import {type HgIndexTableRowProps} from '../../components'
import HgClickableTableBase from '../HgClickableTable/_components/HgClickableTableBase'
import HgFilterSearch from '../HgFilterSearch'
import HgItemsPerPageBlock from '../HgItemsPerPageBlock'
import HgPaginationBlock from '../HgPaginationBlock'
import DatabaseTableRows from './_components/DatabaseTableRows'
import {getFilteredData} from './_utils/getFilteredData'
import {DATABASE_TABLE_TOP_ID} from './constants'
import {type HgDatabaseTableProps} from './types'

const PAGINATION_ITEM_THRESHOLD = 50

const HgDatabaseTable = ({
  lastColumnAlignment,
  isFilterHeaderSticky,
  variant,
  data,
  sortBy = '',
  query = '',
  normalizeRowProps,
  columnTitles,
  hasError,
  filterTabs,
  filterValues,
  filterMap,
  filterDisplayConfig,
  page = '1',
  perPage = '25',
  ...props
}: HgDatabaseTableProps) => {
  const sortableProperties = columnTitles?.length
    ? columnTitles
        .filter(column => column?.sortProperties?.isSortable)
        .map(column => column?.sortProperties?.columnName as string)
    : []

  const shouldShowPagination = data.length > PAGINATION_ITEM_THRESHOLD

  const filteredData = getFilteredData({
    data,
    query,
    sortBy,
    filterMap,
    filters: filterValues,
    sortableProperties,
  })

  const rows = filteredData
    .map(normalizeRowProps)
    .filter(Boolean) as HgIndexTableRowProps[]

  const itemsPerPage = perPage === 'All' ? perPage : parseInt(perPage)
  return (
    <div id={DATABASE_TABLE_TOP_ID} className="scroll-m-navbar-height">
      <div>
        <HgFilterSearch
          isSticky={isFilterHeaderSticky}
          filterTabs={filterTabs}
          filterDisplayConfig={filterDisplayConfig}
          // filter search must always have `s3` bottom padding for case of sticky header
          className="pb-s3"
        />
        {shouldShowPagination && (
          <HgItemsPerPageBlock
            data={rows}
            controlProps={{itemsPerPage}}
            // There must always be `s6` spacing between filter & pagination modules
            className="pt-[calc(var(--s6)-var(--s3))]"
          />
        )}
        <HgClickableTableBase
          {...props}
          className={
            // If pagination module is present, only have `s3` padding, otherwise there should be `s6` padding between
            // filter & table
            shouldShowPagination ? 'pt-s3' : 'pt-[calc(var(--s6)-var(--s3))]'
          }
          columnTitles={columnTitles}
          lastColumnAlignment={lastColumnAlignment}
          variant={variant}
          tableBody={
            <DatabaseTableRows
              rows={rows}
              lastColumnAlignment={lastColumnAlignment}
              variant={variant}
              hasError={hasError}
              page={page}
              perPage={perPage}
            />
          }
        />
      </div>
      {shouldShowPagination && (
        <HgPaginationBlock
          data={rows}
          controlProps={{itemsPerPage}}
          moduleSize="max"
        />
      )}
    </div>
  )
}

export default HgDatabaseTable
