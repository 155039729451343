'use client'

import {Root, TabsContent} from '@radix-ui/react-tabs'
import {
  createContext,
  use,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {Button} from '~/design-system/foundations'
import {useIsLargerThanMobile} from '~/design-system/hooks/useMediaQuery'
import {tv} from 'tailwind-variants'
import HgFilterOptions from './_components/HgFilterOptions'
import HgFilterMenu from './_components/HgFilterMenu'
import HgIcon from '../HgIcon'
import {
  type HgFilterTabStateRecord,
  type HgFilterContextType,
  type HgFilterTabPropsWithContent,
  type HgFilterProps,
  type HgFilterCheckboxTabState,
  type HgFilterRangeTabState,
} from './types'

const HgFilterVariants = tv({
  slots: {
    root: 'flex bg-surface-elevated shadow-[0px_10px_16px_0px_rgba(28,_28,_35,_0.02),_0px_6px_10px_0px_rgba(28,_28,_35,_0.04),_0px_0px_3px_0px_rgba(28,_28,_35,_0.09)] md:h-[436px] md:rounded-xl',
    desktopView: 'hidden w-full overflow-y-auto md:flex',
    menuContainer:
      'w-full border-border-frosted md:w-[257px] md:overflow-y-auto md:border-r',
    mobileHeader:
      'flex w-full items-center justify-between border-b border-border-frosted px-[24px] py-s5 text-text-default arcadia-ui-1 md:hidden',
    menu: 'block w-full',
    optionsContainer: 'grid md:min-w-[320px] md:overflow-y-auto',
    options: 'col-span-full row-start-1 max-h-screen w-full md:max-h-[436px]',
    mobileView: 'flex min-h-screen w-screen flex-col md:hidden',
    mobileContent: 'h-full w-full overflow-y-auto px-s3',
  },
})

export const HgFilterContext = createContext<HgFilterContextType | null>(null)

const MOBILE_MENU_TAB_VALUE = 'mobile-menu'

export default function HgFilter({
  tabs,
  onFilterChange,
  closeMenu,
  initialState,
}: HgFilterProps) {
  const [tabState, setTabState] = useState<HgFilterTabStateRecord>(
    initialState || {}
  )
  const isNotMobile = useIsLargerThanMobile()
  const [selectedTab, setSelectedTab] = useState<string>(
    isNotMobile ? tabs[0].tabValue : MOBILE_MENU_TAB_VALUE
  )
  const {
    root,
    desktopView,
    menuContainer,
    mobileHeader,
    mobileContent,
    menu,
    optionsContainer,
    options,
    mobileView,
  } = HgFilterVariants()

  const initialRenderRef = useRef(true)
  const previousIsNotMobileRef = useRef(isNotMobile)

  useEffect(() => {
    if (!isNotMobile) {
      setSelectedTab(MOBILE_MENU_TAB_VALUE)
    }
  }, [isNotMobile])

  // handling "active" tab on rerenders
  useEffect(() => {
    const handleResize = () => {
      if (!isNotMobile && previousIsNotMobileRef.current) {
        // Switching from desktop to mobile
        setSelectedTab(MOBILE_MENU_TAB_VALUE)
      } else if (isNotMobile && !previousIsNotMobileRef.current) {
        // Switching from mobile to desktop
        setSelectedTab(prev =>
          prev === MOBILE_MENU_TAB_VALUE ? tabs[0].tabValue : prev
        )
      }
      previousIsNotMobileRef.current = isNotMobile
    }

    if (initialRenderRef.current) {
      initialRenderRef.current = false
    } else {
      handleResize()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [tabs, isNotMobile])

  useEffect(() => {
    onFilterChange(tabState)
  }, [tabState, onFilterChange])

  const appendNumberToLabel = useCallback(
    (tab: HgFilterTabPropsWithContent) => {
      const state = tabState[tab.tabValue]
      let count = 0

      if (tab.type === 'checkbox') {
        count = (state as HgFilterCheckboxTabState)?.length || 0
      } else if (tab.type === 'radio') {
        count = state ? 1 : 0
      } else if (tab.type === 'range') {
        const rangeState = state as HgFilterRangeTabState
        count =
          rangeState?.min !== undefined || rangeState?.max !== undefined ? 1 : 0
      }

      return (
        <>
          {tab.label}
          {count > 0 && ` (${count})`}
        </>
      )
    },
    [tabState]
  )

  const totalFilterCount = useMemo(() => {
    return Object.values(tabState).reduce((total, state) => {
      if (Array.isArray(state)) {
        return total + state.length
      } else if (typeof state === 'object') {
        const rangeState = state
        return (
          total +
          (rangeState?.min !== undefined || rangeState?.max !== undefined ? 1 : 0)
        )
      } else if (state !== undefined) {
        return total + 1
      }
      return total
    }, 0)
  }, [tabState])

  const handleTabChange = useCallback((value: string) => {
    setSelectedTab(value)
  }, [])

  const handleBack = useCallback(() => {
    if (!isNotMobile) {
      setSelectedTab(MOBILE_MENU_TAB_VALUE)
    }
  }, [isNotMobile])

  const renderMobileView = () => (
    <>
      <div className={mobileView()}>
        <div className={mobileHeader()}>
          {selectedTab !== MOBILE_MENU_TAB_VALUE ? (
            <Button onClick={handleBack} className="flex items-center gap-[8px]">
              <HgIcon iconType="chevron-left" size="small" />
              <span>Back</span>
            </Button>
          ) : (
            <div className="flex flex-row items-center gap-s1">
              <HgIcon iconType="filter" size="small" />
              <span>
                Add Filters {totalFilterCount > 0 && `(${totalFilterCount})`}
              </span>
            </div>
          )}
          <Button onClick={closeMenu} aria-label="close menu">
            <HgIcon iconType="xmark" size="small" />
          </Button>
        </div>
        <div className={mobileContent()}>
          {selectedTab === MOBILE_MENU_TAB_VALUE ? (
            <div className={menu()}>
              <HgFilterMenu
                tabs={tabs.map(tab => ({
                  ...tab,
                  label: appendNumberToLabel(tab),
                  onClick: () => {
                    handleTabChange(tab.tabValue)
                  },
                }))}
              />
            </div>
          ) : (
            <div className="block w-full">
              {tabs.map(tab => (
                <HgFilterOptions
                  key={tab.tabValue}
                  {...tab}
                  className={tab.tabValue === selectedTab ? options() : 'hidden'}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <TabsContent value={MOBILE_MENU_TAB_VALUE} />
    </>
  )

  const renderDesktopView = () => (
    <div className={desktopView()}>
      <div className={menuContainer()}>
        <HgFilterMenu
          tabs={tabs.map(tab => ({
            ...tab,
            label: appendNumberToLabel(tab),
          }))}
        />
      </div>
      <div className={optionsContainer()}>
        {tabs.map(tab => (
          <HgFilterOptions key={tab.tabValue} {...tab} className={options()} />
        ))}
      </div>
    </div>
  )

  return (
    <HgFilterContext value={{tabState, setTabState}}>
      <Root
        orientation="vertical"
        className={root()}
        value={selectedTab}
        onValueChange={handleTabChange}
      >
        {renderMobileView()}
        {renderDesktopView()}
      </Root>
    </HgFilterContext>
  )
}

export function useHgFilterTabContext() {
  const context = use(HgFilterContext)
  if (!context) {
    throw new Error('useHgFilterTabContext must be used within a HgFilter')
  }
  return context
}
