'use client'

import {useState, useRef, useEffect, useCallback} from 'react'
import {HgAspectRatio} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

function ImageContainer({
  observerThreshold,
  children,
}: {
  observerThreshold: number
  children: React.ReactNode
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      {rootMargin: `${observerThreshold}px 0px`}
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [observerThreshold])

  return (
    <div
      ref={ref}
      className={cn(
        'transition-all duration-500',
        isVisible ? 'animate-fadeIn opacity-100 blur-0 ease-in' : 'opacity-0 blur-md'
      )}
    >
      {children}
    </div>
  )
}

export type HgParallaxImageGridProps = {
  images: React.ReactNode[]
  animationThreshold: number
}

export default function HgParallaxImageGrid({
  images,
  animationThreshold,
}: HgParallaxImageGridProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [scrollProgress, setScrollProgress] = useState(0)
  const parallaxOffset = animationThreshold * 2

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight

      /**
       * The parallax begins `parallaxOffset`px after entering the viewport
       * and ends `parallaxOffset`px before leaving.
       */
      const progress =
        (windowHeight - parallaxOffset - rect.top) /
        (windowHeight + rect.height - 2 * parallaxOffset)
      const clampedProgress = Math.min(Math.max(progress, 0), 1)
      setScrollProgress(clampedProgress)
    }
  }, [parallaxOffset])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [handleScroll])

  const translateValue = parallaxOffset * scrollProgress

  const third = Math.ceil(images.length / 3)
  const columns = [
    images.slice(0, third),
    images.slice(third, 2 * third),
    images.slice(2 * third),
  ]

  return (
    <div className="max-w-full overflow-x-hidden">
      <div
        className="grid w-[200%] -translate-x-1/4 grid-cols-[repeat(24,_minmax(0,_1fr))] items-start gap-s4 md:w-full md:translate-x-0 md:grid-cols-3"
        style={{
          marginTop: `${translateValue}px`,
          marginBottom: `${translateValue}px`,
        }}
        ref={containerRef}
      >
        {columns.map((column, idx) => (
          <div
            key={`column-${idx}`}
            className="col-span-8 grid gap-s4 md:col-span-1"
            style={{
              transform: `translateY(${idx % 2 === 0 ? '-' : ''}${translateValue}px)`,
            }}
          >
            {column.map((el, idx) => (
              <ImageContainer
                key={`column-${idx}-${idx}`}
                observerThreshold={animationThreshold}
              >
                <HgAspectRatio aspectRatio="4:5">{el}</HgAspectRatio>
              </ImageContainer>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
