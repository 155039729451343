import {tv, type VariantProps} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {
  HgMediaCaption,
  type HgMediaCaptionProps,
} from '~/design-system/hg/components'

export const hgMediaCaptionTextVariants = tv({
  base: 'grid grid-cols-1 gap-x-s4 gap-y-s6',
  variants: {
    columns: {
      '2': 'md:grid-cols-2',
      '3': 'md:grid-cols-3',
      '4': 'md:grid-cols-4',
    },
  },
})

export type MediaCaptionTextColumns = NonNullable<
  VariantProps<typeof hgMediaCaptionTextVariants>['columns']
>

export type HgMediaCaptionTextProps = {
  columns: MediaCaptionTextColumns
  items: HgMediaCaptionProps[]
}

export default function HgMediaCaptionText({
  columns,
  items,
}: HgMediaCaptionTextProps) {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto pt-s9'})}>
        <div
          className={gridItem({
            size: 'max',
            class: hgMediaCaptionTextVariants({columns}),
          })}
        >
          {items.map((item, index) => (
            <HgMediaCaption {...item} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}
