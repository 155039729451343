import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {gridItem} from '~/design-system/foundations'

const unorderedListVariants = tv({
  base: 'list-outside ps-[1.25em] text-text-default tiempos-body-2 [&_li_+_li]:mt-s2 [&_li_>_ul]:mt-s2',
  variants: {
    nestedLevel: {
      0: 'list-["⬩⠀"]',
      1: 'list-["▫⠀"]',
      2: 'list-["-⠀"]',
    },
  },
})

type HgUnorderedListNestedLevel = NonNullable<
  VariantProps<typeof unorderedListVariants>['nestedLevel']
>

export type HgUnorderedListProps = PropsWithChildren<{
  className?: string
  nestedLevel: HgUnorderedListNestedLevel
}>

export const HgUnorderedList = ({
  children,
  className,
  nestedLevel,
}: HgUnorderedListProps) => {
  return (
    <ul className={unorderedListVariants({nestedLevel, className})}>{children}</ul>
  )
}

export const HgArticleWYSIWYGUnorderedList = ({
  nestedLevel,
  className,
  ...props
}: HgUnorderedListProps) => {
  return (
    <HgUnorderedList
      {...props}
      nestedLevel={nestedLevel || 0}
      className={gridItem({size: 'heading', className})}
    />
  )
}
