import {get} from 'lodash'
import {colors, type AuraColorToken} from './colors'
import {
  darkThemes,
  lightThemes,
  type DarkTheme,
  type LightTheme,
  type Theme,
} from './themes'

export function getHexValueFromToken(token?: AuraColorToken): string | undefined {
  if (!token) return

  const tokenKeys = token.split('-')

  if (
    tokenKeys.includes('magic') &&
    (tokenKeys.includes('600') || tokenKeys.includes('400'))
  ) {
    tokenKeys.push('DEFAULT')
  }
  return get(colors, tokenKeys)
}

export function isDarkTheme(themeName?: string): themeName is DarkTheme {
  return darkThemes.includes(themeName as DarkTheme)
}

export function isLightTheme(themeName?: string): themeName is LightTheme {
  return lightThemes.includes(themeName as LightTheme)
}

export function isTheme(themeName?: string): themeName is Theme {
  return isLightTheme(themeName) || isDarkTheme(themeName)
}

export function getDarkThemeName(themeName?: string): DarkTheme {
  if (isDarkTheme(themeName)) {
    return themeName
  }

  // Default to darkNeutral
  return 'darkNeutral'
}

export function getThemeName(themeName?: string): Theme {
  if (isTheme(themeName)) {
    return themeName
  }

  // Default to neutral
  return 'neutral'
}
