import {Fragment} from 'react'
import {HgTextLink} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {internalLinks} from '~/routing/internal-links'
import ArticleLinkBox from '../ArticleLinkBox'
import {type HgArticleListingTextOnlyProps} from './types'
import {hgArticleListingTextOnlyVariants} from './variants'

export default function HgArticleListingTextOnly({
  topic,
  title,
  subheading,
  showSubheading = true,
  authors,
  size,
  alignment = 'left',
  slug,
  topicLinkVariant = 'primary',
  classNames,
}: HgArticleListingTextOnlyProps) {
  const {
    outerContainer,
    innerContainer,
    topicLink,
    title: titleClass,
    subheading: subheadingClass,
    authorContainer,
  } = hgArticleListingTextOnlyVariants({
    size,
    alignment,
    topicLinkVariant,
  })
  const shouldShowSubheading = showSubheading && subheading

  return (
    <ArticleLinkBox
      slug={slug}
      className={outerContainer({className: classNames?.container})}
    >
      <div className={innerContainer()}>
        <div
          className={cn(
            'relative z-[2] col-span-full w-fit arcadia-ui-4',
            classNames?.topic
          )}
        >
          <HgTextLink
            analyticsEventName="blog-topic"
            href={internalLinks.blogTopic(topic.slug)}
            className={topicLink()}
            variant="revealUnderline"
          >
            {topic.name}
          </HgTextLink>
        </div>
        {slug ? (
          <HgTextLink
            analyticsEventName="blog-article"
            href={internalLinks.blogArticle(slug)}
            variant="revealUnderline"
            className={titleClass({
              className: cn('relative z-[2]', classNames?.title),
            })}
          >
            <h2>{title}</h2>
          </HgTextLink>
        ) : (
          <h2 className={titleClass({className: classNames?.title})}>{title}</h2>
        )}
        {shouldShowSubheading && (
          <div className={subheadingClass()}>{subheading}</div>
        )}
        {authors?.length && (
          <div
            className={authorContainer({
              className: cn('relative z-[2]', classNames?.authorsContainer),
            })}
          >
            <span className="text-text-subdued arcadia-ui-1">By</span>
            {authors.map((author, idx) => (
              <Fragment key={idx}>
                <div className="inline arcadia-ui-1">
                  {authors.length > 1 && idx === authors.length - 1 ? ' and ' : ' '}
                  {author.slug ? (
                    <HgTextLink
                      analyticsEventName="blog-author"
                      href={author.slug}
                      variant="underline"
                    >
                      {author.name}
                    </HgTextLink>
                  ) : (
                    <span>{author.name}</span>
                  )}
                  {idx < authors.length - 1 && authors.length > 2 && ', '}
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </ArticleLinkBox>
  )
}
