import {
  HgIndexTableRow,
  type HgIndexTableRowProps,
} from '~/design-system/hg/components'
import HgClickableTableBase, {
  type HgClickableTableBaseProps,
} from './_components/HgClickableTableBase'

export type HgIndexTableRows = Array<{id: string} & HgIndexTableRowProps>

export type HgClickableTableProps = Omit<HgClickableTableBaseProps, 'tableBody'> & {
  rows: HgIndexTableRows
}

const HgClickableTable = ({
  rows,
  lastColumnAlignment,
  variant,
  ...rest
}: HgClickableTableProps) => {
  return (
    <HgClickableTableBase
      {...rest}
      tableBody={rows.map(row => (
        <HgIndexTableRow
          key={row.id}
          lastColumnAlignment={lastColumnAlignment}
          variant={variant}
          {...row}
        />
      ))}
      lastColumnAlignment={lastColumnAlignment}
      variant={variant}
    ></HgClickableTableBase>
  )
}

export default HgClickableTable
