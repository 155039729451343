import * as React from 'react'

const Pagination = ({className, ...props}: React.ComponentProps<'nav'>) => (
  <nav role="navigation" aria-label="pagination" className={className} {...props} />
)

const PaginationContent = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'ul'>) => (
  <ul ref={ref} className={className} {...props} />
)

const PaginationItem = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'li'>) => (
  <li ref={ref} className={className} {...props} />
)

export {Pagination, PaginationContent, PaginationItem}
