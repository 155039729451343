'use client'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {usePathname} from 'next/navigation'
import {HgTextLink} from '~/design-system/hg/components'
import {type HgNavigationBaseLinkProps} from './types'

export type HgNavigationExternalLinkProps = HgNavigationBaseLinkProps & {
  closeNav?: () => void
  isExternal?: boolean
}

export const HgNavigationExternalLink = ({
  ref,
  href,
  label,
  closeNav,
  isExternal,
  ...linkProps
}: HgNavigationExternalLinkProps) => {
  const pathname = usePathname()
  const isActive = href === pathname

  return (
    <NavigationMenu.Link onClick={closeNav} asChild active={isActive}>
      <HgTextLink
        {...linkProps}
        className="arcadia-ui-1"
        href={href}
        newTab
        variant="revealUnderline"
        ref={ref}
      >
        {label}
      </HgTextLink>
    </NavigationMenu.Link>
  )
}
