'use client'

import {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactElement,
  type RefObject,
  type CSSProperties,
} from 'react'
import {type Theme} from '~/design-system/hg/tokens/colors'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'
import {usePrefersReducedMotion} from '~/hooks/useMediaQuery'
import {type AspectRatio} from '../HgAspectRatio'
import './styles.css'
import {createPortal} from 'react-dom'
import {
  type AnimationControlButtonVariant,
  HgAnimationButtons,
  RESET_DURATION,
} from './HgAnimationButtons'

type PreloadOptions = 'auto' | 'metadata' | 'none'

type VideoAssetProps = {
  ref: RefObject<HTMLVideoElement | null>
  style: CSSProperties
  className?: string
  playsInline?: boolean
  poster?: string
  preload?: PreloadOptions
  blurDataURL?: string
  muted?: boolean
  onEnded?: () => void
  onLoadedMetadata?: () => void
  onTimeUpdate?: () => void
}

export type HgAnimationProps = {
  aspectRatio?: AspectRatio
  poster?: string
  controlsTheme?: Theme
  showVolumeButton?: boolean
  asset: ReactElement<VideoAssetProps>
  blurDataURL?: string
  onPlayError: (error: unknown) => void
  preload?: PreloadOptions
  buttonContainerId?: string
  buttonVariant?: AnimationControlButtonVariant
  loop?: boolean
  className?: string
  autoplayVisibilityThreshold?: number
}

const aspectRatioMap: Record<AspectRatio, string> = {
  '16:5': '16/5',
  '16:9': '16/9',
  '4:5': '4/5',
  '5:4': '5/4',
  '3:2': '3/2',
  '2:3': '2/3',
  '1:1': '1/1',
  '2:1': '2/1',
  '9:16': '9/16',
  'none': 'auto',
}

const HgAnimation = ({
  aspectRatio,
  poster,
  controlsTheme = 'neutral',
  showVolumeButton = false,
  asset,
  blurDataURL,
  onPlayError = () => {},
  preload = 'metadata',
  buttonContainerId,
  buttonVariant = 'frosted',
  loop = false,
  autoplayVisibilityThreshold = 0.5,
  className,
}: HgAnimationProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isEnded, setIsEnded] = useState(false)
  const [duration, setDuration] = useState(0)
  const [showLoopAnimation, setShowLoopAnimation] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [buttonContainerRef, setButtonContainerRef] =
    useState<HTMLDivElement | null>(null)
  const shouldAutoplay = useIsVisible(videoRef, {
    rootMargin: '0px',
    threshold: autoplayVisibilityThreshold,
  })
  const shouldDisableAnimation = usePrefersReducedMotion()
  const circleProgressRef = useRef<SVGCircleElement>(null)

  const pause = () => {
    videoRef.current?.pause()
    setIsPlaying(false)
  }

  const play = useCallback(() => {
    if (!videoRef.current) {
      return
    }
    // In order to play on safari w/o user interaction, autoplay must be true. However, we don't
    // want to play animation until it is in view, hence why we wait to set autoplay attribute
    videoRef.current.autoplay = true
    videoRef.current
      .play()
      .then(() => {
        setIsPlaying(true)
        setIsEnded(false)
      })
      .catch(err => {
        onPlayError(err)
      })
  }, [onPlayError])

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      pause()
    } else {
      play()
      setIsEnded(false)
    }
  }

  const handleVideoEnded = () => {
    if (loop) {
      play()
      return
    }
    setIsEnded(true)
    setIsPlaying(false)
  }

  const handleLoadedMetadata = () => {
    setDuration((videoRef.current?.duration || 0) * 1000)
  }

  useEffect(() => {
    if (shouldDisableAnimation) {
      return
    }
    if (shouldAutoplay && !isEnded) {
      play()
    }
    if (!shouldAutoplay) {
      pause()
    }
  }, [shouldDisableAnimation, shouldAutoplay, isEnded, play])

  useEffect(() => {
    if (!buttonContainerId) return
    const externalButtonContainer = document.getElementById(
      buttonContainerId
    ) as HTMLDivElement
    if (!externalButtonContainer) return
    setButtonContainerRef(externalButtonContainer)
  }, [buttonContainerId])

  const animationButtonProps = {
    isPlaying,
    isMuted,
    setIsMuted,
    isEnded,
    duration,
    handlePlayPauseClick,
    showVolumeButton,
    buttonVariant,
    controlsTheme,
    loop,
    circleProgressRef,
  }

  return (
    <>
      <div className="col-start-1 col-end-auto row-start-1 row-end-auto h-full min-h-0">
        {cloneElement(asset, {
          ref: videoRef,
          style: {
            aspectRatio: aspectRatio ? aspectRatioMap[aspectRatio] : undefined,
            minHeight: '100%',
            minWidth: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          },
          className,
          playsInline: true,
          poster,
          preload,
          blurDataURL,
          muted: isMuted,
          onEnded: handleVideoEnded,
          onLoadedMetadata: handleLoadedMetadata,
          onTimeUpdate: () => {
            if (!loop || showLoopAnimation) return

            if (!circleProgressRef.current || !videoRef.current) return

            const circleProgress = circleProgressRef.current

            const currentTime = videoRef.current.currentTime * 1000
            const targetDuration = duration - RESET_DURATION

            // if looping, show the reset animation before playing the default progression animation
            // this ensures there is no overlap
            if (currentTime >= targetDuration) {
              setShowLoopAnimation(true)
              circleProgress.setAttribute('data-loop', 'true')
              // by using the current time of the video, we can ensure the animation and video are in sync
              circleProgress.addEventListener(
                'animationend',
                () => {
                  setShowLoopAnimation(false)
                },
                {once: true}
              )
              return
            }
            circleProgress.removeAttribute('data-loop')
          },
        })}
      </div>
      {buttonContainerRef ? (
        createPortal(
          <HgAnimationButtons {...animationButtonProps} />,
          buttonContainerRef
        )
      ) : (
        <HgAnimationButtons {...animationButtonProps} />
      )}
    </>
  )
}

export default HgAnimation
