'use client'

import * as AvatarPrimitive from '@radix-ui/react-avatar'
import * as React from 'react'
import {cn} from '~/design-system/utils'

const Avatar = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof AvatarPrimitive.Root>) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex h-[44px] w-[44px] shrink-0 overflow-hidden rounded',
      className
    )}
    {...props}
  />
)

const AvatarImage = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof AvatarPrimitive.Image>) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
)

const AvatarFallback = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof AvatarPrimitive.Fallback>) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded',
      className
    )}
    {...props}
  />
)

export {Avatar, AvatarFallback, AvatarImage}
