import React from 'react'
import {
  HgCaption,
  type HgCaptionProps,
  HgTextLink,
  type HgTextLinkProps,
  HgAspectRatio,
  type HgAspectRatioVariants,
} from '~/design-system/hg/components'

export type HgMediaCaptionProps = {
  asset: React.ReactNode
  captionProps: Omit<HgCaptionProps, 'iconType'>
  linkProps?: Omit<HgTextLinkProps, 'variant'>
  aspectRatioProps: Pick<HgAspectRatioVariants, 'aspectRatio' | 'borderRadius'>
}

export default function HgMediaCaption({
  aspectRatioProps,
  captionProps,
  linkProps,
  asset,
}: HgMediaCaptionProps) {
  return (
    <div className="space-y-s3">
      <HgAspectRatio {...aspectRatioProps}>{asset}</HgAspectRatio>

      <HgCaption iconType={null} {...captionProps} />

      {linkProps ? (
        <div>
          <HgTextLink variant="underline" {...linkProps} />
        </div>
      ) : null}
    </div>
  )
}
