import {grid} from '~/design-system/foundations'
import HgPromoNewsletter, {
  type HgPromoNewsletterProps,
} from '~/design-system/hg/components/HgArticlePromos/_components/HgPromoNewsletter'

export type HgPromoEmailCaptureProps = HgPromoNewsletterProps

const HgPromoEmailCapture = ({
  heading,
  body,
  newsletters,
  successMessage,
  onSubmit,
  width,
}: HgPromoEmailCaptureProps) => {
  return (
    <div className={grid({className: 'mx-auto mt-s8'})}>
      <HgPromoNewsletter
        heading={heading}
        body={body}
        newsletters={newsletters}
        successMessage={successMessage}
        onSubmit={onSubmit}
        width={width}
      />
    </div>
  )
}

export default HgPromoEmailCapture
