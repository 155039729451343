import React, {type ReactNode} from 'react'
import {tv} from 'tailwind-variants'

export type HgComposableDetailsContentProps = {
  variant: 'oneColumn' | 'twoColumn' | 'threeColumn'
  children: ReactNode
}

const hgComposableDetailsContentVariants = tv({
  base: 'grid w-full gap-s4',
  variants: {
    numCols: {
      oneColumn: 'grid-cols-1',
      twoColumn: 'grid-cols-2',
      threeColumn: 'grid-cols-3',
    },
  },
})

const HgComposableDetailsContent: React.FC<HgComposableDetailsContentProps> = ({
  variant,
  children,
}) => {
  return (
    <div className={hgComposableDetailsContentVariants({numCols: variant})}>
      {children}
    </div>
  )
}

export default HgComposableDetailsContent
