import {HgAspectRatio} from '~/design-system/hg/components'
import HgArticleListingTextOnly from '../TextOnly'
import ArticleLinkBox from '../ArticleLinkBox'
import {type HgArticleListingMiniProps} from './types'
import {hgArticleListingMiniVariants} from './variants'

const HgArticleListingMini = ({
  image,
  slug,
  ...textProps
}: HgArticleListingMiniProps) => {
  const {wrapper, imageWrapper} = hgArticleListingMiniVariants()
  return (
    <ArticleLinkBox className={wrapper()} slug={slug}>
      <HgArticleListingTextOnly {...textProps} slug={slug} size="heading-10" />
      <HgAspectRatio
        aspectRatio="1:1"
        classNames={{
          wrapper: imageWrapper(),
          assetWrapper: 'rounded-none',
        }}
      >
        {image}
      </HgAspectRatio>
    </ArticleLinkBox>
  )
}

export default HgArticleListingMini
