'use client'
import _ from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {
  HgDisclaimer,
  HgHeading,
  type HgEmailCaptureProps,
  type HgHeadingButtonGroupProps,
  type HgHeadingContentTypeProps,
  type HgHeadingNoContent,
  type HgHeadingProps,
  type HgHeadingVariationProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {type BreadcrumbLinkProps} from '../../components/HgBreadcrumb'
import CinematicVariant, {
  type HgHeroCinematicProps,
} from './_components/CinematicVariant'
import DefaultVariant, {type HgHeroDefaultProps} from './_components/DefaultVariant'
import SplitImageVariant, {
  type HgHeroSplitImageProps,
} from './_components/SplitImageVariant'

type HgHeroHeadingOnly = {
  type: 'headingOnly'
}

export type HgHeroTypeDependentProps =
  | HgHeroCinematicProps
  | HgHeroDefaultProps
  | HgHeroHeadingOnly
  | HgHeroSplitImageProps

export type HgHeroEmailCaptureCTAProps = {
  contentType: 'emailCapture'
  emailCaptureProps?: HgEmailCaptureProps
}

export type HgHeroCTAProps =
  | HgHeadingButtonGroupProps
  | HgHeroEmailCaptureCTAProps
  | HgHeadingNoContent

export type HgHeroBaseProps = {
  headline: React.ReactNode
  disclaimer?: string | React.ReactNode
  subhead?: React.ReactNode
  className?: string
  breadcrumbs?: BreadcrumbLinkProps[]
  alignment?: HgHeadingProps['alignment']
} & HgHeroCTAProps

export type HgHeroProps = HgHeroBaseProps & HgHeroTypeDependentProps

const hgHeroVariants = tv({
  slots: {
    gridSlot:
      'z-[3] col-start-1 col-end-auto row-start-1 row-end-auto mx-auto pt-navbar-height',
    headingContainer: '',
    heading: 'z-[1] text-text-default arcadia-display-6',
  },
  variants: {
    type: {
      cinematic: {
        headingContainer: 'lg:col-span-10 lg:col-start-4',
      },
      default: {
        heading: '',
      },
      headingOnly: {
        heading: '',
      },
      splitImage: {
        gridSlot: 'lg:px-s5',
        heading: 'items-start justify-center text-left',
        headingContainer:
          'row-start-1 flex flex-col gap-32 self-start self-center pt-s9 md:col-start-1 lg:col-span-7 lg:col-start-1 lg:data-[sticky=true]:sticky lg:data-[sticky=true]:top-[var(--top)] lg:data-[sticky=true]:self-start',
      },
    },
    alignment: {
      left: '',
      center: '',
    },
    size: {
      long: '',
      short: '',
    },
  },
  compoundVariants: [
    {
      type: 'splitImage',
      size: 'long',
      class: {
        headingContainer: 'md:col-span-full',
      },
    },
    {
      type: 'splitImage',
      size: 'short',
      class: {
        gridSlot: 'w-full',
        headingContainer: 'xl:col-span-5 xl:col-start-3',
      },
    },
    {
      type: 'headingOnly',
      alignment: 'left',
      class: {
        gridSlot: 'w-full',
        heading: 'w-full items-start justify-center text-left',
        headingContainer:
          'col-span-full col-start-1 md:col-span-9 md:col-start-1 lg:col-start-3 xl:col-span-10 xl:col-start-3 3xl:col-span-8 3xl:col-start-3',
      },
    },
  ],
})

export default function HgHero(props: HgHeroProps) {
  const {headline, disclaimer, subhead, className, breadcrumbs, alignment} = props
  const containerRef = useRef<HTMLDivElement>(null)
  const headingContainerRef = useRef<HTMLDivElement>(null)
  const [disclaimerVisible, setDisclaimerVisible] = useState(true)
  const [disclaimerHidden, setDisclaimerHidden] = useState(false)
  const isCinematic = props.type === 'cinematic'
  const isSplitImage = props.type === 'splitImage'
  const isDefault = props.type === 'default'

  const {heading, headingContainer, gridSlot} = hgHeroVariants({
    type: props.type,
    size: isSplitImage ? props.size : undefined,
    alignment: alignment || 'center',
  })

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setDisclaimerVisible(false)
      } else {
        setDisclaimerVisible(true)
        setDisclaimerHidden(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!isSplitImage || !containerRef.current) return

    function calculateTop() {
      if (!containerRef.current || !headingContainerRef.current) return

      if (window.innerHeight < containerRef.current.clientHeight) {
        headingContainerRef.current.setAttribute('data-sticky', 'true')
      } else {
        headingContainerRef.current.setAttribute('data-sticky', 'false')
      }

      const containerHeight = window.innerHeight
      const childHeight = headingContainerRef.current.clientHeight
      const topValue = (containerHeight - childHeight) / 2
      headingContainerRef.current.style.setProperty('--top', `${topValue}px`)
    }
    const debouncedCalculateTop = _.debounce(calculateTop, 100)
    debouncedCalculateTop()
    window.addEventListener('resize', debouncedCalculateTop)
    return () => {
      debouncedCalculateTop.cancel()
      window.removeEventListener('resize', debouncedCalculateTop)
    }
  }, [isSplitImage])

  function getHeadingContentTypeProps(): HgHeadingContentTypeProps {
    switch (props.contentType) {
      case 'emailCapture': {
        if (!props.emailCaptureProps) break
        let emailCaptureProps = props.emailCaptureProps
        if (props.type === 'splitImage') {
          emailCaptureProps = {
            ...emailCaptureProps,
            alwaysStacked: true,
          }
        }

        return {
          emailCaptureProps,
          contentType: 'emailCapture',
        }
      }
      case 'buttonGroup':
        return {
          contentType: 'buttonGroup',
          primaryButtonProps: props.primaryButtonProps,
          secondaryButtonProps: props.secondaryButtonProps
            ? {
                ...props.secondaryButtonProps,
                variant: isCinematic ? 'frosted' : 'tonal',
              }
            : undefined,
        }
      case 'none':
      default:
        return {contentType: 'none'}
    }
    return {contentType: 'none'}
  }

  function getHeadingVariationProps(): HgHeadingVariationProps {
    switch (props.type) {
      case 'splitImage':
        return {
          variation: 'section',
          topSpacing: 's0',
        }
      case 'default':
      case 'headingOnly':
      case 'cinematic':
      default:
        return {
          variation: 'hero',
          topSpacing: 's8',
        }
    }
  }

  return (
    <div
      className={cn(
        'group grid h-fit w-full',
        isCinematic && 'min-h-lvh',
        className
      )}
      ref={containerRef}
      onPointerMove={event => {
        if (!containerRef.current || !isCinematic) return

        const container = containerRef.current
        const rect = container.getBoundingClientRect()

        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        container.style.setProperty('--x', `${x}px`)
        container.style.setProperty('--y', `${y}px`)
      }}
    >
      {isCinematic && <CinematicVariant {...props} />}
      <div
        className={grid({
          className: gridSlot(),
        })}
      >
        <div
          className={gridItem({
            size: 'heading',
            className: headingContainer(),
          })}
          ref={headingContainerRef}
        >
          {isSplitImage && props.logoAsset && (
            <div className="w-fit max-w-full rounded-xl bg-surface-default p-s3 arcadia-body-1">
              {props.logoAsset}
            </div>
          )}
          <HgHeading
            {...getHeadingContentTypeProps()}
            {...getHeadingVariationProps()}
            breadcrumbs={breadcrumbs}
            className={heading()}
            headline={headline}
            subhead={subhead}
            alignment={isSplitImage ? 'left' : alignment}
          />
        </div>
        {isDefault && <DefaultVariant {...props} />}
        {isSplitImage && <SplitImageVariant {...props} />}
      </div>
      {disclaimer && (
        <div
          data-visible={disclaimerVisible}
          aria-hidden={!disclaimerVisible && disclaimerHidden}
          className="fixed bottom-0 left-0 right-0 z-[3] mx-s5 flex justify-center opacity-0 transition-[opacity] duration-300 aria-[hidden=true]:invisible data-[visible=true]:opacity-100"
          onTransitionEnd={() => {
            if (!disclaimerVisible) setDisclaimerHidden(true)
          }}
        >
          <HgDisclaimer centered variant="contained" bottomSpacing="s5">
            {disclaimer}
          </HgDisclaimer>
        </div>
      )}
    </div>
  )
}
