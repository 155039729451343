import {tv} from 'tailwind-variants'

export const hgArticleListingStackedVariants = tv({
  slots: {
    wrapper: 'w-full',
    innerContainer: 'grid w-full grid-cols-1 gap-s4',
    textContainer: '',
    imageWrapper: 'h-full w-full',
  },
  variants: {
    size: {
      'heading-2': {
        textContainer: '3xl:max-w-[928px]',
      },
      'heading-5': {
        textContainer: '3xl:max-w-[808px]',
      },
      'heading-6': {},
      'heading-7': {},
    },
    textAlignment: {
      center: {
        innerContainer: 'justify-items-center',
      },
      left: {},
    },
  },
  compoundVariants: [],
})
