'use client'
import {Canvas} from '@react-three/fiber'
import {useEffect, useRef, useState} from 'react'
import Simulation from '~/design-system/hg/components/StableFluidBackground/components/Simulation'
import {type StableFluidBackgroundSettings} from '~/design-system/hg/components/StableFluidBackground/types'

type StableFluidBackgroundProps = {
  gradientMapPath?: string
  backgroundColorHex?: string
} & StableFluidBackgroundSettings

/*
None of this code would be possible without the awesome write up, demo, and repo written by Misaki Nakano.
Referenced blog post: https://mofu-dev.com/en/blog/stable-fluids/
Repository: https://github.com/mnmxmx/fluid-three
*/

export default function StableFluidBackground({
  gradientMapPath = '/design-system/hg/default-stable-fluid-bg.png',
  backgroundColorHex = '#FFFFFF',
  cursorSize = 100,
  mouseForce = 20,
  poissonIterations = 32,
  resolution = 0.5,
}: StableFluidBackgroundProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [webGLError, setWebGLError] = useState(false)
  const [devicePixelRatio, setDevicePixelRatio] = useState<number>()
  // only generate the scene when hovered for better performance
  const [hovered, setHovered] = useState(false)
  const hoverTimerRef = useRef<NodeJS.Timeout>(undefined)

  const settings = {
    cursorSize,
    mouseForce,
    poissonIterations,
    resolution,
  }

  useEffect(() => {
    setDevicePixelRatio(window.devicePixelRatio)
  }, [])

  // If the browser is unable to create a WebGL context for some reason (like if hardware acceleration is disabled in Chrome), the page will crash, so we need to gracefully handle the error
  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const gl = canvas.getContext('webgl2')
      if (!gl || gl.getError() !== gl.NO_ERROR) {
        setWebGLError(true)
      }
    }
  }, [canvasRef])

  if (webGLError) {
    return null
  }

  return (
    <>
      <Canvas
        ref={canvasRef}
        gl={{
          autoClear: false,
          pixelRatio: devicePixelRatio,
        }}
        onPointerEnter={() => {
          clearTimeout(hoverTimerRef.current)

          if (!canvasRef.current) return
          setHovered(true)
        }}
        onMouseLeave={() => {
          clearTimeout(hoverTimerRef.current)

          hoverTimerRef.current = setTimeout(() => {
            setHovered(false)
          }, 500)
        }}
        className="opacity-0 transition-opacity duration-500 hover:opacity-100"
      >
        {hovered && (
          <Simulation
            canvasRef={canvasRef}
            gradientMap={gradientMapPath}
            backgroundColor={backgroundColorHex}
            {...settings}
          />
        )}
      </Canvas>
    </>
  )
}
