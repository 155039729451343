'use client'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {usePathname} from 'next/navigation'
import {LinkWrapper} from '~/design-system/foundations'
import {hgAccordionVariants} from '../../modules'
import {type HgNavigationBaseLinkProps} from './types'

export const HgNavigationItemLink = ({
  ref,
  label,
  href,
  tabIndex,
  ...linkProps
}: HgNavigationBaseLinkProps) => {
  const pathname = usePathname()
  const isActive = href === pathname
  const {accordionTrigger, accordionItem} = hgAccordionVariants()

  return (
    <NavigationMenu.Item
      className={accordionItem({
        className:
          'border-border-subdued lg:border-none lg:p-0 lg:arcadia-ui-1 lg:last:p-s0 lg:[&:not(:first-child)]:m-0',
      })}
    >
      <NavigationMenu.Link tabIndex={tabIndex} asChild active={isActive}>
        <LinkWrapper
          {...linkProps}
          href={href}
          ref={ref}
          className={accordionTrigger({
            className:
              'flex text-nowrap bg-inherit text-text-default active:text-text-emphasized lg:items-center lg:rounded-full lg:px-16 lg:py-8 lg:outline-2 lg:outline-offset-4 lg:outline-border-focus lg:duration-300 lg:ease-out lg:arcadia-ui-1 lg:hover:bg-surface-emphasized lg:hover:text-text-default lg:focus-visible:bg-surface-emphasized lg:focus-visible:text-text-default lg:focus-visible:outline lg:active:bg-surface-emphasized lg:active:text-text-emphasized',
          })}
        >
          <p>{label}</p>
        </LinkWrapper>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  )
}
