import {type ReactNode} from 'react'
import {LinkBox, LinkOverlay} from '~/design-system/foundations'
import {internalLinks} from '~/routing/internal-links'

const ArticleLinkBox = ({
  className,
  slug,
  children,
}: {
  className?: string
  children: ReactNode
  slug: string
}) => {
  return (
    <LinkBox className={className}>
      {children}
      <LinkOverlay
        data-analytics-event-name="blog-article"
        href={internalLinks.blogArticle(slug)}
        aria-hidden
        tabIndex={-1}
      />
    </LinkBox>
  )
}

export default ArticleLinkBox
