'use client'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'

export type CheckboxProps = React.ComponentPropsWithRef<
  typeof CheckboxPrimitive.Root
> & {
  indicator?: React.ReactNode
}

const Checkbox = ({ref, className, indicator, ...props}: CheckboxProps) => (
  <CheckboxPrimitive.Root ref={ref} className={className} {...props}>
    <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
      {indicator}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
)

export {Checkbox}
