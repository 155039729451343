export {
  default as HgArticlePromoProduct,
  type HgPromoProductProps as HgArticlePromoProductProps,
} from './_components/HgPromoProduct'

export {
  default as HgArticlePromoRecirculation,
  type HgPromoRecirculationProps as HgArticlePromoRecirculationProps,
} from './_components/HgPromoRecirculation'

export {
  type HgBlogNewsletterList,
  default as HgArticlePromoNewsletter,
  type HgPromoNewsletterProps as HgArticlePromoNewsletterProps,
} from './_components/HgPromoNewsletter'
