import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import React from 'react'

const HoverCardRoot = HoverCardPrimitive.Root

const HoverCardTrigger = ({
  ref,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof HoverCardPrimitive.Trigger>) => (
  <HoverCardPrimitive.Trigger asChild ref={ref} {...props}>
    {children}
  </HoverCardPrimitive.Trigger>
)

const HoverCardContent = ({
  ref,
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof HoverCardPrimitive.Content>) => (
  <HoverCardPrimitive.Portal>
    <HoverCardPrimitive.Content ref={ref} className={className} {...props}>
      {children}
    </HoverCardPrimitive.Content>
  </HoverCardPrimitive.Portal>
)

export {HoverCardContent, HoverCardRoot, HoverCardTrigger}
