import {HgArticleListing} from '../../components'
import {
  type ArticleSize,
  type HgArticleListingStackedProps,
  type HgArticleListingStackedVariants,
} from './types'
import {hgArticleListingStackedVariants} from './variants'

// Size determination based on width and number of articles
const determineSize = (
  width: HgArticleListingStackedProps['width'],
  articleCount: number
): ArticleSize => {
  if (width === 'fullWidth') {
    return articleCount === 2 ? 'heading-5' : 'heading-7'
  }
  return articleCount === 2 ? 'heading-6' : 'heading-7'
}

const HgArticleListingStacked = ({
  articles,
  width,
  alignment,
  aspectRatio,
  showListingSubheading,
}: HgArticleListingStackedProps) => {
  const articleCount = articles.length
  const size = determineSize(width, articles.length)
  const {container, innerContainer, content} = hgArticleListingStackedVariants({
    width,
    articleCount: articleCount.toString() as NonNullable<
      HgArticleListingStackedVariants['articleCount']
    >,
  })

  const showSubheading =
    !!showListingSubheading && articles.every(article => !!article.subheading)

  return (
    <div className={container()}>
      <div className={innerContainer()}>
        <div className={content()}>
          {articles.map((article, idx) => (
            <HgArticleListing
              key={idx}
              aspectRatio={aspectRatio}
              variant="stacked"
              textAlignment={alignment}
              size={size}
              showSubheading={showSubheading}
              {...article}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HgArticleListingStacked
