'use client'
import type * as LabelPrimitive from '@radix-ui/react-label'
import {Slot} from '@radix-ui/react-slot'
import {type ComponentPropsWithRef, createContext, use, useId} from 'react'
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  FormProvider,
  useFormContext,
  type UseFormProps,
  // eslint-disable-next-line no-restricted-imports
  useForm as useReactHookForm,
} from 'react-hook-form'
import {cn} from '~/design-system/utils'
import Label from '../Label'

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
)

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext value={{name: props.name}}>
      <Controller {...props} />
    </FormFieldContext>
  )
}

const useFormField = () => {
  const fieldContext = use(FormFieldContext)
  const itemContext = use(FormItemContext)
  const {getFieldState, formState} = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const {id} = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = createContext<FormItemContextValue>(
  {} as FormItemContextValue
)

const FormItem = ({ref, ...props}: React.ComponentPropsWithRef<'div'>) => {
  const id = useId()

  return (
    <FormItemContext value={{id}}>
      <div ref={ref} {...props} />
    </FormItemContext>
  )
}

const FormLabel = ({
  ref,
  className,
  ...props
}: ComponentPropsWithRef<typeof LabelPrimitive.Root>) => {
  const {error, formItemId} = useFormField()

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-text-error', className)}
      htmlFor={formItemId}
      {...props}
    />
  )
}

const FormControl = ({ref, ...props}: ComponentPropsWithRef<typeof Slot>) => {
  const {error, formItemId, formDescriptionId, formMessageId} = useFormField()

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  )
}

const FormDescription = ({ref, ...props}: React.ComponentPropsWithRef<'p'>) => {
  const {formDescriptionId} = useFormField()

  return <p ref={ref} id={formDescriptionId} {...props} />
}

const FormMessage = ({
  ref,
  children,
  ...props
}: React.ComponentPropsWithRef<'p'>) => {
  const {formMessageId} = useFormField()

  if (!children) {
    return null
  }

  return (
    <p ref={ref} id={formMessageId} {...props}>
      {children}
    </p>
  )
}

/**
 * Wraps `useForm` to set some defaults.
 */
function useForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>
) {
  return useReactHookForm<TFieldValues, TContext>({
    mode: 'onTouched',
    ...props,
  })
}

export {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
  useForm,
  useFormField,
}
