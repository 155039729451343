import {tv, type VariantProps} from 'tailwind-variants'
import {type ExtractStrict, type OmitStrict} from 'type-zoo'
import {TextTag} from '~/design-system/foundations'
import {
  HgButtonLink,
  type LabelButtonProps,
  type HgButtonLinkProps,
} from '~/design-system/hg/components'
import HgEmailCapture, {
  type HgEmailCaptureProps,
} from '~/design-system/hg/components/HgEmailCapture'
import HgBreadcrumb, {type BreadcrumbLinkProps} from '../HgBreadcrumb'

export type HgHeadingButton = Omit<
  HgButtonLinkProps,
  'variant' | 'size' | 'iconType'
> &
  LabelButtonProps

export type HgHeadingEmailProps = {
  contentType: 'emailCapture'
  emailCaptureProps: HgEmailCaptureProps
}

export type HgHeadingButtonGroupProps = {
  contentType: 'buttonGroup'
  primaryButtonProps?: HgHeadingButton
  secondaryButtonProps?: HgHeadingButton & {
    variant?: Extract<HgButtonLinkProps['variant'], 'tonal' | 'frosted'>
  }
}

export type HgHeadingNoContent = {
  contentType: 'none'
}

export type HgHeadingContentTypeProps =
  | HgHeadingEmailProps
  | HgHeadingButtonGroupProps
  | HgHeadingNoContent

type HgHeadingTVProps = VariantProps<typeof hgHeadingVariants>

type HgHeadingBaseProps = {
  headline: React.ReactNode
  subhead?: React.ReactNode
  className?: string
  breadcrumbs?: BreadcrumbLinkProps[]
} & OmitStrict<HgHeadingTVProps, 'topSpacing' | 'variation'>

export type HgHeadingHeroProps = {
  variation: 'hero'
  topSpacing?: ExtractStrict<HgHeadingTVProps['topSpacing'], 's0' | 's8'>
}

export type HgHeadingSectionProps = {
  variation: 'section'
  topSpacing?: ExtractStrict<HgHeadingTVProps['topSpacing'], 's0' | 's12'>
}

export type HgHeadingVariationProps = HgHeadingHeroProps | HgHeadingSectionProps

export type HgHeadingProps = HgHeadingVariationProps &
  HgHeadingContentTypeProps &
  HgHeadingBaseProps

const hgHeadingVariants = tv({
  slots: {
    title: 'grid text-text-default',
    container: 'flex flex-col',
    emailCaptureContainer: 'flex w-full sm:w-auto',
    buttonGroup: 'flex gap-s2',
    formItemWrapper: '',
    breadcrumbContainer: 'mb-s6 flex',
  },
  variants: {
    topSpacing: {
      s0: {
        container: 'pt-s0',
      },
      s8: {
        container: 'pt-s8',
      },
      s12: {
        container: 'pt-s12',
      },
    },
    alignment: {
      left: {
        container: 'items-start text-left',
        emailCaptureContainer: 'items-start text-left',
      },
      center: {
        container: 'items-center text-center',
        emailCaptureContainer: 'items-center text-center',
        formItemWrapper: 'items-center',
        breadcrumbContainer: 'justify-center',
      },
    },
    variation: {
      hero: {
        title: 'arcadia-display-6',
        buttonGroup: 'mt-s6',
        emailCaptureContainer: 'mt-s6',
      },
      section: {
        title: 'arcadia-subheading-2',
        buttonGroup: 'mt-s5',
        emailCaptureContainer: 'mt-s5',
      },
    },
  },
})

function HgHeadingButtonGroup(
  props: Partial<Omit<HgHeadingButtonGroupProps, 'type'>> & {className: string}
) {
  if (!props.primaryButtonProps && !props.secondaryButtonProps) return null
  return (
    <div className={props.className}>
      {props.primaryButtonProps && (
        <HgButtonLink variant="primary" {...props.primaryButtonProps} />
      )}
      {props.secondaryButtonProps && (
        <HgButtonLink
          {...props.secondaryButtonProps}
          variant={props.secondaryButtonProps.variant ?? 'tonal'}
        />
      )}
    </div>
  )
}

export default function HgHeading({
  headline,
  subhead,
  alignment = 'center',
  className,
  breadcrumbs,
  ...props
}: HgHeadingProps) {
  const {
    container,
    title,
    emailCaptureContainer,
    buttonGroup,
    formItemWrapper,
    breadcrumbContainer,
  } = hgHeadingVariants({
    topSpacing: props.topSpacing ?? 's0',
    alignment,
    variation: props.variation,
  })

  function renderVariation() {
    switch (props.contentType) {
      case 'emailCapture': {
        const {emailCaptureProps} = props

        return (
          <div className={emailCaptureContainer()}>
            <HgEmailCapture
              classNames={{formItemWrapper: formItemWrapper()}}
              secondaryLinkProps={emailCaptureProps.secondaryLinkProps}
              {...emailCaptureProps}
            />
          </div>
        )
      }
      case 'buttonGroup':
        return <HgHeadingButtonGroup {...props} className={buttonGroup()} />
      case 'none':
      default:
        return null
    }
  }
  return (
    <div className={container({class: className})}>
      <div className="flex flex-col">
        {breadcrumbs && breadcrumbs.length > 0 && (
          <HgBreadcrumb
            breadcrumbs={breadcrumbs}
            className={breadcrumbContainer()}
          />
        )}
        <TextTag
          tag={props.variation === 'section' ? 'h2' : 'h1'}
          className={title()}
        >
          {headline}
        </TextTag>
        {subhead && (
          <div className="mt-s3 space-y-[1em] text-text-subdued arcadia-body-1 [&>span]:block">
            {subhead}
          </div>
        )}
      </div>
      {renderVariation()}
    </div>
  )
}
