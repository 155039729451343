import React from 'react'
import {
  LinkBox,
  LinkOverlay,
  type LinkWrapperProps,
} from '~/design-system/foundations'
import {HgAspectRatio, HgTextLink} from '~/design-system/hg/components'

export type HgTopicListingProps = {
  topicName: React.ReactNode
  subheading: React.ReactNode
  oneOneAsset: React.ReactNode
  linkProps: LinkWrapperProps
}

export default function HgTopicListing({
  topicName,
  subheading,
  oneOneAsset,
  linkProps,
}: HgTopicListingProps) {
  return (
    <LinkBox>
      <div className="flex flex-row gap-x-s3">
        <HgAspectRatio
          aspectRatio={'1:1'}
          classNames={{
            assetWrapper: 'rounded-none',
            wrapper: 'min-w-56 max-w-56 shrink-0 md:max-w-64 3xl:max-w-72',
          }}
        >
          {oneOneAsset}
        </HgAspectRatio>

        <div className="flex flex-col">
          <HgTextLink
            className="arcadia-heading-9"
            variant="revealUnderline"
            {...linkProps}
          >
            {topicName}
          </HgTextLink>
          <p className={'arcadia-body-2'}>{subheading}</p>
        </div>
      </div>
      <LinkOverlay {...linkProps} aria-hidden tabIndex={-1} />
    </LinkBox>
  )
}
