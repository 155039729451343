import {type ReactNode} from 'react'
import {grid} from '~/design-system/foundations'
import {
  type HgButtonLinkProps,
  type HgTextLinkProps,
} from '~/design-system/hg/components'
import HgPromoProduct from '~/design-system/hg/components/HgArticlePromos/_components/HgPromoProduct'

export type HgPromoProductCTAProps = {
  heading: ReactNode
  body?: ReactNode
  promoLink: {
    type: 'button' | 'text'
    linkProps:
      | Omit<HgTextLinkProps, 'variant'>
      | Omit<HgButtonLinkProps, 'variant' | 'size'>
  }
  sixteenNineAsset?: ReactNode
  width: 'inset' | 'fullWidth'
}

const HgPromoProductCTA = ({
  heading,
  body,
  promoLink,
  sixteenNineAsset,
  width,
}: HgPromoProductCTAProps) => {
  return (
    <div className={grid({className: 'mx-auto mt-s8'})}>
      <HgPromoProduct
        heading={heading}
        body={body}
        promoLink={promoLink}
        sixteenNineAsset={sixteenNineAsset}
        width={width}
      />
    </div>
  )
}

export default HgPromoProductCTA
