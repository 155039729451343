'use client'

import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {grid, gridItem} from '~/design-system/foundations'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHeader,
  TableRow,
} from '~/design-system/foundations/Table'
import {
  HgIndexTableTitleRow,
  HgTextBlock,
  type HgIndexTableTitleRowCellProps,
  type IndexTableVariantProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgClickableTableBaseProps = {
  sectionTitle?: React.ReactNode
  columnTitles?: HgIndexTableTitleRowCellProps
  caption?: React.ReactNode
  lastColumnAlignment?: IndexTableVariantProps['lastColumnAlignment']
  variant?: 'asymmetrical' | 'simple'
  tableBody: React.ReactNode
  className?: string
}

const HgClickableTableBase = ({
  sectionTitle,
  columnTitles,
  caption,
  lastColumnAlignment,
  variant,
  tableBody,
  className,
}: HgClickableTableBaseProps) => {
  return (
    <div className="w-full">
      <div className={grid({className: cn('mx-auto', className)})}>
        <div className={gridItem({size: 'max'})}>
          <Table
            classNames={{
              table: 'caption-top border-0',
              wrapper:
                'mx-auto overflow-visible border-b border-border-subdued has-[tbody>tr:last-child:hover]:border-transparent-transparent has-[tr:last-child.border-b-0]:border-transparent-transparent has-[tr:last-child:focus-within]:border-transparent-transparent',
            }}
          >
            {sectionTitle && (
              <TableCaption className="pb-s4 text-left arcadia-subheading-5">
                {sectionTitle}
              </TableCaption>
            )}
            {columnTitles?.length && (
              <TableHeader>
                <HgIndexTableTitleRow
                  lastColumnAlignment={lastColumnAlignment}
                  row={columnTitles}
                  variant={variant}
                />
              </TableHeader>
            )}
            <TableBody>
              <ErrorBoundary
                fallback={
                  <TableRow className="border-b-0">
                    <TableCell>
                      <HgTextBlock
                        title="Failed to load"
                        body="Try refreshing the page"
                        alignment="center"
                        topPadding="s9"
                      />
                    </TableCell>
                  </TableRow>
                }
              >
                {tableBody}
              </ErrorBoundary>
            </TableBody>
          </Table>
        </div>
        {caption && (
          <small className="col relative col-span-full mt-s3 w-full text-left text-text-default arcadia-body-3 before:absolute before:-left-[1ch] before:content-['*'] md:col-span-6 lg:col-start-3 3xl:col-span-4 3xl:col-start-3">
            {caption}
          </small>
        )}
      </div>
    </div>
  )
}

export default HgClickableTableBase
