import {tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'

export const hgArticleListingStackedVariants = tv({
  slots: {
    container: grid({className: 'mx-auto pt-s8'}),
    innerContainer: '',
    content: 'grid w-full grid-cols-1 gap-s6 md:gap-s4',
  },
  variants: {
    width: {
      fullWidth: {
        innerContainer: gridItem(),
      },
      inset: {
        innerContainer: gridItem({size: 'max'}),
      },
    },
    articleCount: {
      '2': {
        content: 'md:grid-cols-2',
      },
      '3': {
        content: 'md:grid-cols-3',
      },
      '4': {
        content: 'md:grid-cols-4',
      },
    },
  },
})
