import Skeleton from 'react-loading-skeleton'
import {LinkWrapper} from '~/design-system/foundations'

import {
  HgAspectRatio,
  HgHoverCard,
  HgIcon,
  type HgTableCellProps,
  HgTagGroup,
  type HgIconType,
  type HgTagProps,
  type HgTableTextCell,
} from '~/design-system/hg/components'
import {cn, unreachableCase} from '~/design-system/utils'

const IconCell = ({
  iconType,
  className,
}: {
  iconType: HgIconType
  className?: string
}) => {
  return (
    <div className={cn('flex h-full w-full items-center', className)}>
      <HgIcon
        size="small"
        iconType={iconType}
        className="text-icon-default md:hidden"
      />
      <HgIcon
        size="medium"
        iconType={iconType}
        className="hidden text-icon-default md:block lg:hidden"
      />
      <HgIcon
        size="large"
        iconType={iconType}
        className="hidden text-icon-default lg:block"
      />
    </div>
  )
}

const TagCell = ({tags, className}: {tags: HgTagProps[]; className?: string}) => {
  return (
    <div className={cn('flex h-full w-full items-center', className)}>
      <HgTagGroup canWrap={false} tags={tags} />
    </div>
  )
}

const TextContentCell = ({
  title,
  iconType,
  body,
  image,
  className,
}: HgTableTextCell & {className?: string}) => (
  <div
    className={cn(
      'flex h-full w-full flex-row items-center space-x-s3 overflow-hidden',
      className
    )}
  >
    {image && (
      <HgAspectRatio
        aspectRatio="1:1"
        classNames={{
          wrapper:
            'flex h-full max-h-40 min-h-40 w-full min-w-40 max-w-40 md:max-h-48 md:min-h-48 md:min-w-48 md:max-w-48 lg:max-h-56 lg:min-h-56 lg:min-w-56 lg:max-w-56 [&>*]:h-full [&>*]:w-full [&_svg]:h-full [&_svg]:w-full',
        }}
      >
        {image}
      </HgAspectRatio>
    )}
    <div className="flex flex-col items-start justify-items-center">
      {title && (
        <p className="flex flex-1 items-center text-text-default arcadia-heading-9">
          {title}
          {iconType && <HgIcon iconType={iconType} size="small" className="ms-s2" />}
        </p>
      )}
      {body && (
        <p className="flex flex-1 items-center text-text-default arcadia-body-2">
          {body}
        </p>
      )}
    </div>
  </div>
)

const HgTableCell = ({
  isLoading,
  hoverCardProps,
  className,
  ...props
}: HgTableCellProps) => {
  if (isLoading) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <Skeleton containerClassName="flex-1" />
      </div>
    )
  }

  const getCellContent = () => {
    switch (props.type) {
      case 'icon':
        return <IconCell {...props} />
      case 'tags':
        return <TagCell {...props} />
      case 'text':
        return <TextContentCell {...props} />
      case 'empty':
        return null
      default:
        unreachableCase(props)
    }
  }

  if (hoverCardProps) {
    return (
      <HgHoverCard
        {...hoverCardProps}
        // Avoid collision with nav + sticky filter header at widest breakpoint
        collisionPadding={136}
        trigger={
          <LinkWrapper
            className="relative z-[1] rounded outline-2 outline-offset-4 outline-border-focus focus-visible:outline"
            href={hoverCardProps.href}
            data-analytics-event-name={hoverCardProps.analyticsEventName}
          >
            {getCellContent()}
          </LinkWrapper>
        }
      />
    )
  }

  return getCellContent()
}

export default HgTableCell
