import {type ReactNode} from 'react'
import {gridItem} from '~/design-system/foundations'
import {HgBodyText} from '../HgBodyText'
import {HgMediaAsset, type HgArticleWYSIWYGMediaAssetProps} from '../HgMediaAsset'
import {cn} from '~/design-system/utils'

type HgArticleWYSIWYGSplitAssetAndBodyProps = {
  body: ReactNode
  assetProps: HgArticleWYSIWYGMediaAssetProps
  classNames?: {wrapper?: string; asset?: string}
}

export const HgArticleWYSIWYGSplitAssetAndBody = ({
  body,
  assetProps,
  classNames,
}: HgArticleWYSIWYGSplitAssetAndBodyProps) => {
  return (
    <div
      className={gridItem({
        size: 'heading',
        className: cn(
          'grid grid-cols-subgrid gap-y-s4 lg:col-span-10 lg:col-start-3',
          classNames?.wrapper
        ),
      })}
    >
      <div className="col-span-full md:col-span-4 lg:col-span-5">
        <div className="sticky top-[calc((var(--s5)_+_var(--navbar-height))_-_var(--s2))] md:pt-s2">
          <HgMediaAsset classNames={{wrapper: classNames?.asset}} {...assetProps} />
        </div>
      </div>
      <div className="col-span-full md:col-span-4 lg:col-span-5">
        <HgBodyText variant="body-2">{body}</HgBodyText>
      </div>
    </div>
  )
}
