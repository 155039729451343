import {HgMediaAsset, type HgArticleWYSIWYGMediaAssetProps} from '../HgMediaAsset'
import {tv, type VariantProps} from 'tailwind-variants'

const mediaGrid = tv({
  base: 'grid gap-x-s4',
  variants: {
    size: {
      small: '',
      medium: '',
      large: '',
    },
    numberOfAssets: {
      2: 'col-span-full gap-y-s5 md:grid-cols-2',
      3: 'col-span-full gap-y-s5 md:grid-cols-3 lg:col-span-12 lg:col-start-3',
      4: 'col-span-full grid-cols-2 gap-y-s4 md:col-span-8 md:col-start-3 lg:col-span-full lg:grid-cols-4',
    },
  },
  compoundVariants: [
    {
      size: 'small',
      numberOfAssets: 2,
      class: 'grid-cols-2 md:col-span-8 md:col-start-3 lg:col-span-8 lg:col-start-5',
    },
    {
      size: 'medium',
      numberOfAssets: 2,
      class: 'md:col-span-10 md:col-start-2 lg:col-span-12 lg:col-start-3',
    },
  ],
})

export type HgArticleWYSIWYGMediaGridProps = {
  assets: [
    HgArticleWYSIWYGMediaAssetProps,
    HgArticleWYSIWYGMediaAssetProps,
    HgArticleWYSIWYGMediaAssetProps?,
    HgArticleWYSIWYGMediaAssetProps?,
  ]
  size?: VariantProps<typeof mediaGrid>['size']
  className?: string
}

export const HgArticleWYSIWYGMediaGrid = ({
  assets,
  size,
  className,
}: HgArticleWYSIWYGMediaGridProps) => {
  return (
    <div className={mediaGrid({numberOfAssets: assets.length, size, className})}>
      {assets.map(
        (asset, index) => asset && <HgMediaAsset key={index} {...asset} />
      )}
    </div>
  )
}
