import {tv, type VariantProps} from 'tailwind-variants'
import {HgButton, type HgButtonProps} from '~/design-system/hg/components'

type HgUiControlsProps = {
  numPages: number
  currentIndex: number
  onForward: () => void
  onBack: () => void
  buttonSize?: HgButtonProps['size']
  showCount?: boolean
  hideForward?: boolean
  hideBack?: boolean
  leftIcon?: 'arrow-left' | 'chevron-left'
  rightIcon?: 'arrow-right' | 'chevron-right'
} & VariantProps<typeof controlWrapper>

const controlWrapper = tv({
  base: 'flex items-center gap-s4',
  variants: {
    topSpacing: {
      s0: 'mt-s0',
      s6: 'mt-s6',
      s9: 'mt-s9',
    },
  },
})

export default function HgUiControls({
  numPages,
  currentIndex,
  onForward,
  onBack,
  showCount = true,
  hideBack,
  hideForward,
  topSpacing,
  buttonSize = 'medium',
  leftIcon = 'arrow-left',
  rightIcon = 'arrow-right',
}: HgUiControlsProps) {
  return (
    <div className={controlWrapper({topSpacing})}>
      {showCount && (
        <span className="w-40 whitespace-nowrap text-text-default arcadia-ui-1">
          {currentIndex + 1} / {numPages}
        </span>
      )}
      <div className="grid grid-cols-2 gap-s2">
        {!hideBack && (
          <HgButton
            contentType="icon"
            variant="tonal"
            iconProps={{iconType: leftIcon}}
            onClick={onBack}
            aria-label="Back"
            size={buttonSize}
          />
        )}
        {!hideForward && (
          <HgButton
            contentType="icon"
            variant={'tonal'}
            onClick={onForward}
            iconProps={{iconType: rightIcon}}
            aria-label="Forward"
            className="col-start-2"
            size={buttonSize}
          />
        )}
      </div>
    </div>
  )
}
