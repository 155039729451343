import {type ReactNode} from 'react'
import {
  HgAspectRatio,
  HgButton,
  HgThemeSelector,
  type AspectRatio,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {tv, type VariantProps} from 'tailwind-variants'
import {type Theme} from '~/design-system/hg/tokens/colors'

const mediaAsset = tv({
  slots: {
    wrapper: '',
    caption: '',
  },
  variants: {
    size: {
      xs: {
        wrapper:
          'col-span-10 col-start-2 md:col-span-6 md:col-start-4 lg:col-start-6',
      },
      small: {wrapper: 'col-span-full md:col-span-8 md:col-start-3 lg:col-start-5'},
      medium: {wrapper: 'col-span-full lg:col-span-12 lg:col-start-3'},
      large: {wrapper: 'col-span-full'},
      xl: {
        caption: 'px-s5',
        wrapper:
          'col-span-full -mx-s5 w-screen [@media_(min-width:_var(--grid-max-width))]:mx-[calc(-100vw_/_2_+_var(--grid-max-width)_/_2_-32px)]',
      },
    },
  },
})

export type HgMediaAssetProps = {
  asset: ReactNode
  assetId: string
  aspectRatio: Exclude<AspectRatio, 'none' | '16:5' | '2:1'>
  caption?: ReactNode
  credit?: ReactNode
  showLightboxButton?: boolean
  openLightbox?: (assetId: string) => void
  classNames?: {
    wrapper?: string
    caption?: string
  }
  controlsTheme?: Theme
}

export type HgArticleWYSIWYGMediaAssetProps = HgMediaAssetProps &
  NonNullable<VariantProps<typeof mediaAsset>>

export const HgMediaAsset = ({
  asset,
  assetId,
  aspectRatio,
  caption,
  credit,
  showLightboxButton = true,
  openLightbox,
  classNames,
  controlsTheme,
}: HgMediaAssetProps) => {
  return (
    <figure className={cn('flex h-fit flex-col space-y-s2', classNames?.wrapper)}>
      <div className="relative flex h-full min-h-0 w-full max-w-full flex-1 items-center justify-center">
        <HgAspectRatio aspectRatio={aspectRatio} borderRadius="0px">
          {asset}
        </HgAspectRatio>
        {showLightboxButton && (
          <HgThemeSelector theme={controlsTheme}>
            <HgButton
              variant="frosted"
              contentType="icon"
              iconProps={{iconType: 'expand'}}
              onClick={() => {
                if (openLightbox) {
                  openLightbox(assetId)
                }
              }}
              aria-label="Open media gallery"
              className="pointer-events-auto absolute bottom-s3 right-s3"
            />
          </HgThemeSelector>
        )}
      </div>
      {(caption || credit) && (
        <figcaption
          className={cn(
            'flex flex-row flex-wrap justify-between gap-s1 text-text-emphasized arcadia-body-3',
            classNames?.caption
          )}
        >
          {caption}
          {credit && <span className="text-text-subdued">{credit}</span>}
        </figcaption>
      )}
    </figure>
  )
}

export const HgArticleWYSIWYGMediaAsset = ({
  classNames,
  ...props
}: HgArticleWYSIWYGMediaAssetProps) => {
  const {wrapper, caption} = mediaAsset({size: props.size})
  return (
    <HgMediaAsset
      {...props}
      classNames={{
        wrapper: wrapper({class: classNames?.wrapper}),
        caption: caption({class: classNames?.caption}),
      }}
    />
  )
}
