import {grid, gridItem} from '~/design-system/foundations'
import {
  HgTopicListing as HgTopicListingComponent,
  type HgTopicListingProps as HgTopicListingComponentProps,
} from '~/design-system/hg/components'

export type HgTopicListingProps = {
  topics: HgTopicListingComponentProps[]
}

export default function HgTopicListing({topics}: HgTopicListingProps) {
  return (
    <div className="w-full">
      <div
        className={grid({
          className: 'mx-auto px-s5 pt-s6',
        })}
      >
        <div
          className={gridItem({
            class:
              'grid grid-cols-1 gap-s4 md:grid-cols-2 xl:grid-cols-4 3xl:gap-y-s5',
          })}
        >
          {topics.map((topic, index) => (
            <HgTopicListingComponent {...topic} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}
