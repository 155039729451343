import {useId} from 'react'
import HgCheckbox, {type HgCheckboxProps} from '../HgCheckbox'
import {Label} from '~/design-system/foundations'
import {cn} from '~/design-system/utils'

export type HgCheckboxInputProps = {
  options: HgCheckboxProps[]
  label: React.ReactNode
  secondaryLabel?: React.ReactNode
  classNames?: {
    container?: string
    optionsContainer?: string
    label?: string
    secondaryLabel?: string
  }
}

export default function HgCheckboxInput({
  options,
  label,
  secondaryLabel,
  classNames,
}: HgCheckboxInputProps) {
  const labelId = useId()
  const secondaryLabelId = useId()

  return (
    <div
      className={cn(
        'flex flex-col text-text-subdued hover:text-text-default',
        classNames?.container
      )}
    >
      {label && (
        <Label className={cn('arcadia-body-3', classNames?.label)} id={labelId}>
          {label}
        </Label>
      )}
      <div
        aria-labelledby={labelId + (secondaryLabelId ? ` ${secondaryLabelId}` : '')}
        role="group"
        className={cn('flex flex-col gap-s3 pt-s3', classNames?.optionsContainer)}
      >
        {options.map((option, index) => (
          <HgCheckbox key={index} {...option} />
        ))}
      </div>
      {secondaryLabel && (
        <Label
          id={secondaryLabelId}
          className={cn(
            'mt-s1 text-text-subdued arcadia-body-3',
            classNames?.secondaryLabel
          )}
        >
          {secondaryLabel}
        </Label>
      )}
    </div>
  )
}
