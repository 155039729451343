'use client'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import * as React from 'react'
import {cn} from '~/design-system/utils'

const RadioGroup = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof RadioGroupPrimitive.Root>) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn(
        'flex',
        props.orientation === 'vertical' && 'flex-col',
        className
      )}
      {...props}
      ref={ref}
    />
  )
}

export type RadioGroupItemProps = {
  indicator: React.ReactNode
} & React.ComponentPropsWithRef<typeof RadioGroupPrimitive.Item>

const RadioGroupItem = ({
  ref,
  className,
  indicator,
  ...props
}: RadioGroupItemProps) => {
  return (
    <RadioGroupPrimitive.Item ref={ref} className={className} {...props}>
      <RadioGroupPrimitive.Indicator
        asChild
        className="flex items-center justify-center"
      >
        {indicator}
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
}

export {RadioGroup, RadioGroupItem}
