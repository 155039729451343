import * as PopoverPrimitive from '@radix-ui/react-popover'
import * as React from 'react'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = ({
  ref,
  className,
  align = 'center',
  sideOffset = 4,
  collisionPadding,
  ...props
}: React.ComponentPropsWithRef<typeof PopoverPrimitive.Content>) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={className}
      collisionPadding={collisionPadding}
      {...props}
    />
  </PopoverPrimitive.Portal>
)

const PopoverClose = PopoverPrimitive.Close

const PopoverAnchor = PopoverPrimitive.Anchor

export {Popover, PopoverAnchor, PopoverClose, PopoverContent, PopoverTrigger}
