export {default as HgComposableDetailsBody} from './_components/HgComposableDetailsBody'
export {default as HgComposableDetailsContent} from './_components/HgComposableDetailsContent'
export {default as HgComposableDetailsLinkGroup} from './_components/HgComposableDetailsLinkGroup'
export {
  default as HgComposableDetailsList,
  type HgComposableDetailsListItemProps,
} from './_components/HgComposableDetailsList'
export {default as HgComposableDetailsTagGroup} from './_components/HgComposableDetailsTagGroup'
export {
  default as HgComposableDetailsTestimonialSlider,
  type HgComposableDetailsTestimonialSliderTestimonialProps,
} from './_components/HgComposableDetailsTestimonialSlider'
export {default as HgComposableDetailsTitle} from './_components/HgComposableDetailsTitle'
