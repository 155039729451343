import {tv} from 'tailwind-variants'

export const hgArticleListingIndexVariants = tv({
  slots: {
    wrapper: 'col-span-full grid grid-cols-subgrid border-t border-border-frosted',
    innerContainer: 'col-span-full grid grid-cols-subgrid pt-s4',
    imageContainer:
      'col-span-full md:order-2 md:col-span-5 lg:col-span-7 xl:order-3 xl:col-span-4',
    textContainer:
      'col-span-full pt-s4 md:order-1 md:col-span-7 md:pt-0 lg:col-span-9 xl:order-2 xl:col-span-8',
    dateContainer: 'col-span-full hidden xl:order-1 xl:col-span-4 xl:block',
  },
})
