import React, {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import HgFeaturedText, {
  type HgFeaturedTextProps,
} from '~/design-system/hg/components/HgFeaturedText'
import {HgAspectRatio} from '../../components'

export type HgSplitImageProps = {
  asset: React.ReactNode
  title: ReactNode
} & HgFeaturedTextProps &
  VariantProps<typeof hgSplitImageVariants>

const hgSplitImageVariants = tv({
  base: 'flex gap-s4',
  slots: {
    assetContainer: '-order-1',
    contentContainer: 'justify-center gap-x-grid-column-gap sm:grid sm:grid-cols-6',
    featuredText: 'col-span-full justify-center sm:col-span-5',
  },
  variants: {
    imagePosition: {
      left: {
        featuredText: 'sm:col-start-2',
      },
      right: {
        assetContainer: 'sm:order-1',
      },
    },
  },
})

export default function HgSplitImage({
  asset,
  imagePosition = 'left',
  ...contentProps
}: HgSplitImageProps) {
  const {assetContainer, contentContainer, featuredText} = hgSplitImageVariants({
    imagePosition,
  })
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
            class: 'mt-s9 grid gap-s4 sm:grid-cols-2',
          })}
        >
          <div className={contentContainer()}>
            <HgFeaturedText {...contentProps} className={featuredText()} />
          </div>
          <div className={assetContainer()}>
            <HgAspectRatio aspectRatio="4:5">{asset}</HgAspectRatio>
          </div>
        </div>
      </div>
    </div>
  )
}
