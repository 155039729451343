import {Slot} from '@radix-ui/react-slot'
import * as React from 'react'

export type ButtonProps = {
  /**
   * Ability to merge parent props onto its immediate child: https://www.radix-ui.com/primitives/docs/utilities/slot
   */
  asChild?: boolean
} & React.ComponentPropsWithRef<'button'>

const Button = ({ref, asChild = false, ...props}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button'
  return <Comp ref={ref as any} {...props} />
}

export default Button
