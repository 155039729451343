import {type HgPromoNewsletterProps} from '~/design-system/hg/components/HgArticlePromos/_components/HgPromoNewsletter'
import {unreachableCase} from '~/design-system/utils'

export const getPromoModuleSize = (width: HgPromoNewsletterProps['width']) => {
  switch (width) {
    case 'article':
      return 'heading'
    case 'inset':
      return 'max'
    case 'fullWidth':
      return undefined
    case undefined:
      return undefined
    default:
      return unreachableCase(width)
  }
}
