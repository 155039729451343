'use client'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'
import {cn} from '~/design-system/utils'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = ({
  ref,
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Trigger>) => (
  <TooltipPrimitive.Trigger ref={ref} className={className} {...props} asChild>
    {children}
  </TooltipPrimitive.Trigger>
)

const TooltipContent = ({
  ref,
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Content>) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={cn(className, 'max-w-[300px]')}
    {...props}
  >
    {children}
  </TooltipPrimitive.Content>
)

const TooltipArrow = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof TooltipPrimitive.Arrow>) => (
  <TooltipPrimitive.Arrow ref={ref} className={className} {...props} />
)

export {TooltipArrow, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger}
