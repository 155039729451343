'use client'
import {type EmblaOptionsType} from 'embla-carousel'
import {memo, useCallback, useEffect, useState} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {Carousel, CarouselContent, CarouselItem} from '~/design-system/foundations'
import {useCarousel} from '~/design-system/foundations/Carousel'
import {
  HgArticleListing,
  HgUiControls,
  type HgArticleListingMiniProps,
} from '~/design-system/hg/components'
import {useIsLargerThanMobile} from '~/design-system/hooks/useMediaQuery'

const MAX_ARTICLES = 4

export type HgPromoRecirculationProps = {
  title: string
  articles: HgArticleListingMiniProps[]
  className?: string
}

const CAROUSEL_OPTIONS = {
  loop: true,
  align: 'start',
  slidesToScroll: 1,
  containScroll: 'keepSnaps',
  watchDrag: true,
} as EmblaOptionsType

const promoVariants = tv({
  slots: {
    wrapper: 'col-span-full flex flex-col bg-background-secondary p-s4',
    carouselView: 'block xl:hidden',
    gridView: 'hidden gap-32 xl:grid',
    headerControls: 'flex items-start justify-between pb-s6',
    controls: 'block xl:hidden',
    carouselContent: 'flex overflow-y-visible',
  },
  variants: {
    numberOfArticles: {
      1: {
        wrapper: 'md:col-span-8 md:col-start-3 lg:col-start-5',
        carouselView: 'md:hidden',
        gridView: 'md:grid md:grid-cols-1 xl:grid-cols-1',
        controls: 'hidden',
      },
      2: {
        wrapper: 'xl:col-span-8 xl:col-start-5',
        carouselView: 'md:hidden',
        gridView: 'md:grid md:grid-cols-2 xl:grid-cols-2',
        controls: 'md:hidden',
      },
      3: {
        wrapper: 'xl:col-span-12 xl:col-start-3',
        carouselView: 'md:block',
        gridView: 'xl:grid-cols-3',
      },
      4: {
        wrapper: 'xl:col-span-12 xl:col-start-3',
        carouselView: 'md:block',
        gridView: 'xl:grid-cols-4',
      },
    },
  },
})

const carouselItemVariants = tv({
  base: 'shrink-0 basis-full md:basis-[calc(50%-16px)]',
  variants: {
    spacing: {
      default: 'mr-32',
      none: '',
    },
    hasAuthorLink: {
      // keeps text underline from being cut off by carousel overflow styling
      true: 'pb-2',
      false: '',
    },
  },
  defaultVariants: {
    spacing: 'default',
  },
})

type NumberOfArticles = NonNullable<
  VariantProps<typeof promoVariants>['numberOfArticles']
>

const getCarouselItemKey = (article: HgArticleListingMiniProps, index: number) => {
  const title: string = article.title
  return `${index}-${title}`
}

const Header = ({
  numberOfArticles,
  title,
}: {
  numberOfArticles: NumberOfArticles
  title: string
}) => {
  const {scrollNext, scrollPrev, currentIndex} = useCarousel()
  const {controls, headerControls} = promoVariants({
    numberOfArticles,
  })

  return (
    <div className={headerControls()}>
      <p className="text-text-default arcadia-subheading-5">{title}</p>
      <div className={controls()}>
        <HgUiControls
          numPages={numberOfArticles}
          onBack={scrollPrev}
          onForward={scrollNext}
          currentIndex={currentIndex}
          showCount={false}
          buttonSize="small"
        />
      </div>
    </div>
  )
}

const GridView = ({articles}: {articles: HgArticleListingMiniProps[]}) => (
  <>
    {articles.map((article, idx) => (
      <HgArticleListing
        {...article}
        variant="mini"
        key={getCarouselItemKey(article, idx)}
      />
    ))}
  </>
)

const CarouselView = memo(
  ({
    articles,
    numberOfArticles,
  }: {
    articles: HgArticleListingMiniProps[]
    numberOfArticles: NumberOfArticles
  }) => {
    const {carouselContent} = promoVariants({
      numberOfArticles,
    })

    const isLargerThanMobile = useIsLargerThanMobile()
    const {api} = useCarousel()
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleSelect = useCallback(() => {
      setCurrentIndex(api?.selectedScrollSnap() ?? 0)
    }, [api])

    useEffect(() => {
      if (!api || !isLargerThanMobile) return

      api.on('select', handleSelect)
      handleSelect()

      return () => {
        api.off('select', handleSelect)
      }
    }, [api, isLargerThanMobile, handleSelect])

    const getItemSpacing = useCallback(
      (index: number) => {
        // no need to change spacing on mobile
        if (!isLargerThanMobile) return 'default'

        const isLastItemInView =
          index === currentIndex + 1 || index === articles.length - 1

        // Remove margin on last visible item to prevent extra space
        return isLastItemInView ? 'none' : 'default'
      },
      [isLargerThanMobile, currentIndex, articles.length]
    )

    return (
      <CarouselContent className={carouselContent()}>
        {articles.map((article, idx) => (
          <CarouselItem
            key={getCarouselItemKey(article, idx)}
            className={carouselItemVariants({
              spacing: getItemSpacing(idx),
              hasAuthorLink: Boolean(article.authors?.[0].slug),
            })}
          >
            <HgArticleListing {...article} variant="mini" />
          </CarouselItem>
        ))}
      </CarouselContent>
    )
  }
)
CarouselView.displayName = 'CarouselView'

const HgPromoRecirculation = (props: HgPromoRecirculationProps) => {
  const numberOfArticles = Math.min(
    props.articles.length,
    MAX_ARTICLES
  ) as NumberOfArticles

  const {wrapper, carouselView, gridView} = promoVariants({
    numberOfArticles,
  })

  return (
    <div className={wrapper({className: props.className})}>
      <Carousel opts={CAROUSEL_OPTIONS} className="overflow-y-visible">
        <Header numberOfArticles={numberOfArticles} title={props.title} />
        <div className={carouselView()}>
          <CarouselView
            articles={props.articles}
            numberOfArticles={numberOfArticles}
          />
        </div>
      </Carousel>

      <div className={gridView()}>
        <GridView articles={props.articles} />
      </div>
    </div>
  )
}

export default HgPromoRecirculation
