import {HgAspectRatio} from '~/design-system/hg/components'
import {hgArticleListingStackedVariants} from './variants'
import {type HgArticleListingStackedProps} from './types'
import HgArticleListingTextOnly from '../TextOnly'
import ArticleLinkBox from '../ArticleLinkBox'

const HgArticleListingStacked = ({
  aspectRatio,
  slug,
  image,
  textAlignment,
  ...textProps
}: HgArticleListingStackedProps) => {
  const {wrapper, imageWrapper, innerContainer, textContainer} =
    hgArticleListingStackedVariants({
      size: textProps.size,
      textAlignment,
    })

  return (
    <ArticleLinkBox className={wrapper()} slug={slug}>
      <div className={innerContainer()}>
        <HgAspectRatio
          aspectRatio={aspectRatio}
          classNames={{
            wrapper: imageWrapper(),
            assetWrapper: 'rounded-none',
          }}
        >
          {image}
        </HgAspectRatio>
        <div className={textContainer()}>
          <HgArticleListingTextOnly
            {...textProps}
            slug={slug}
            alignment={textAlignment}
          />
        </div>
      </div>
    </ArticleLinkBox>
  )
}

export default HgArticleListingStacked
