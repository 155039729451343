import {grid, gridItem} from '~/design-system/foundations'
import {
  HgButtonLink,
  HgPricingCard,
  type HgButtonLinkProps,
  type HgPricingCardProps,
} from '~/design-system/hg/components'

export type HgPricingCardsProps = {buttonProps: HgButtonLinkProps} & {
  pricingCards: [HgPricingCardProps, HgPricingCardProps, HgPricingCardProps]
}

export default function HgPricingCards({
  buttonProps,
  pricingCards,
}: HgPricingCardsProps) {
  const [pricingCard1, pricingCard2, pricingCard3] = pricingCards
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto pt-s9'})}>
        <div
          className={gridItem({
            size: 'max',
            className:
              'flex flex-col items-center rounded-xl bg-surface-default px-s4 py-s5 md:rounded-3xl md:pr-s3 md:pt-s3',
          })}
        >
          <div className="grid w-full grid-rows-[repeat(3,minmax(0,auto))] gap-s5 md:grid-flow-col md:grid-cols-3">
            <HgPricingCard
              className="col-span-1 row-span-3 grid grid-rows-subgrid py-0 md:py-s5"
              {...pricingCard1}
            />
            <div className="col-span-2 row-span-3 contents grid-cols-2 grid-rows-subgrid gap-s5 rounded-xl bg-surface-elevated px-s4 md:grid md:shadow-[0px_10px_16px_0px_rgba(28,28,35,0.02),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_0px_3px_0px_rgba(28,28,35,0.09)]">
              {[pricingCard2, pricingCard3].map((pricingCard, index) => (
                <HgPricingCard
                  key={index}
                  className="row-span-3 grid grid-rows-subgrid rounded-xl bg-surface-elevated  p-s5 shadow-[0px_10px_16px_0px_rgba(28,28,35,0.02),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_0px_3px_0px_rgba(28,28,35,0.09)] md:bg-transparent-transparent md:px-s0 md:py-s5 md:shadow-transparent-transparent"
                  {...pricingCard}
                />
              ))}
            </div>
          </div>
          <HgButtonLink
            variant="tonal"
            {...buttonProps}
            className="mt-s5 w-full md:w-fit"
          />
        </div>
      </div>
    </div>
  )
}
