// NB: if adding a new theme name, be sure to also define the theme and all of its colors in src/app/(new)/globals.css

export const lightThemes = [
  'neutral',
  'purple',
  'beige',
  'green',
  'blue',
  'orange',
] as const

export type LightTheme = (typeof lightThemes)[number]

export const darkThemes = [
  'darkNeutral',
  'darkPurple',
  'darkBeige',
  'darkGreen',
  'darkBlue',
  'darkOrange',
] as const

export type DarkTheme = (typeof darkThemes)[number]

export type Theme = LightTheme | DarkTheme

export type LightThemeClassName =
  | 'neutral-theme'
  | 'purple-theme'
  | 'beige-theme'
  | 'green-theme'
  | 'blue-theme'
  | 'orange-theme'

export type DarkThemeClassName =
  | 'dark-neutral-theme'
  | 'dark-purple-theme'
  | 'dark-beige-theme'
  | 'dark-green-theme'
  | 'dark-blue-theme'
  | 'dark-orange-theme'

export type ThemeClassName = LightThemeClassName | DarkThemeClassName

export const themeToClassName: Record<Theme, ThemeClassName> = {
  neutral: 'neutral-theme',
  darkNeutral: 'dark-neutral-theme',
  purple: 'purple-theme',
  darkPurple: 'dark-purple-theme',
  beige: 'beige-theme',
  darkBeige: 'dark-beige-theme',
  green: 'green-theme',
  darkGreen: 'dark-green-theme',
  blue: 'blue-theme',
  darkBlue: 'dark-blue-theme',
  orange: 'orange-theme',
  darkOrange: 'dark-orange-theme',
}
