import {type ReactNode} from 'react'
import {tv} from 'tailwind-variants'
import {gridItem} from '~/design-system/foundations'
import {
  HgButtonLink,
  type HgButtonLinkProps,
  HgAspectRatio,
  HgTextLink,
  type HgTextLinkProps,
} from '~/design-system/hg/components'
import {getPromoModuleSize} from '~/design-system/hg/components/HgArticlePromos/utils'
import {cn} from '~/design-system/utils'

export const promoProductVariants = tv({
  slots: {
    container: 'col-span-full flex bg-background-secondary md:flex-row md:gap-s4',
    content: 'flex w-full flex-col gap-s5',
    asset: 'md:min-w-[212px]',
    heading: 'text-text-default',
    body: 'text-text-subdued',
  },
  variants: {
    width: {
      article: {
        container: 'flex-col items-center gap-s7 p-s4',
        asset: 'md:max-w-[448px]',
        heading: 'arcadia-subheading-5',
        body: 'arcadia-body-2',
      },
      inset: {},
      fullWidth: {
        container: 'col-start-1 md:py-s6',
      },
    },
    hasImage: {
      true: {
        content: 'justify-between',
      },
      false: {
        container: 'items-center py-s8 text-center',
        content: 'items-center',
      },
    },
  },
  compoundVariants: [
    {
      width: ['inset', 'fullWidth'],
      class: {
        container: 'flex-col-reverse gap-s4 p-s5',
        asset: 'md:max-w-[896px]',
        heading: 'arcadia-subheading-3',
        body: 'arcadia-body-1',
      },
    },
  ],
})

export type HgPromoProductProps = {
  heading: ReactNode
  body?: ReactNode
  promoLink: {
    type: 'button' | 'text'
    linkProps:
      | Omit<HgTextLinkProps, 'variant'>
      | Omit<HgButtonLinkProps, 'variant' | 'size'>
  }
  sixteenNineAsset: ReactNode
  width?: 'article' | 'inset' | 'fullWidth'
  className?: string
}
const HgPromoProduct = ({
  heading,
  body,
  promoLink,
  sixteenNineAsset,
  width = 'article',
  className,
}: HgPromoProductProps) => {
  const {
    container,
    content,
    asset,
    body: bodyVariant,
    heading: headingVariant,
  } = promoProductVariants({
    width,
    hasImage: !!sixteenNineAsset,
  })

  return (
    <div
      className={gridItem({
        size: getPromoModuleSize(width),
        class: cn(container(), className),
      })}
    >
      <div className={content()}>
        <div className="flex flex-col gap-s2">
          <p className={headingVariant()}>{heading}</p>
          {body && <p className={bodyVariant()}>{body}</p>}
        </div>
        {promoLink.type === 'button' ? (
          <HgButtonLink
            variant="tonal"
            size="medium"
            {...(promoLink.linkProps as HgButtonLinkProps)}
          />
        ) : (
          <HgTextLink
            className="arcadia-body-2"
            {...(promoLink.linkProps as HgTextLinkProps)}
            variant="underline"
          />
        )}
      </div>
      {sixteenNineAsset ? (
        <HgAspectRatio
          aspectRatio="16:9"
          classNames={{
            assetWrapper: 'rounded-none',
            wrapper: asset(),
          }}
        >
          {sixteenNineAsset}
        </HgAspectRatio>
      ) : null}
    </div>
  )
}

export default HgPromoProduct
