'use client'

import * as LabelPrimitive from '@radix-ui/react-label'
import * as React from 'react'

const Label = ({
  ref,
  ...props
}: React.ComponentPropsWithRef<typeof LabelPrimitive.Root>) => (
  <LabelPrimitive.Root ref={ref} {...props} />
)

export default Label
