import {HgAspectRatio} from '~/design-system/hg/components'
import {hgArticleListingSplitVariants} from './variants'
import {type HgArticleListingSplitProps} from './types'
import HgArticleListingTextOnly from '../TextOnly'
import ArticleLinkBox from '../ArticleLinkBox'

const HgArticleListingSplit = ({
  aspectRatio,
  slug,
  image,
  splitRatio,
  imageAlignment,
  ...textProps
}: HgArticleListingSplitProps) => {
  const {wrapper, innerContainer, imageWrapper, textContainer} =
    hgArticleListingSplitVariants({splitRatio, imageAlignment})
  const textSize = splitRatio === '75/25' ? 'heading-7' : 'heading-6'

  return (
    <ArticleLinkBox className={wrapper()} slug={slug}>
      <div className={innerContainer()}>
        <HgAspectRatio
          aspectRatio={aspectRatio}
          borderRadius="0px"
          classNames={{
            wrapper: imageWrapper(),
          }}
        >
          {image}
        </HgAspectRatio>
        <div className={textContainer()}>
          <HgArticleListingTextOnly {...textProps} slug={slug} size={textSize} />
        </div>
      </div>
    </ArticleLinkBox>
  )
}

export default HgArticleListingSplit
