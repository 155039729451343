import React from 'react'
import type {VariantProps} from 'tailwind-variants'
import {tv} from 'tailwind-variants'

import {TextTag, type TextTagProps} from '~/design-system/foundations'
import HgIcon, {type HgIconType} from '~/design-system/hg/components/HgIcon'

const textWrapper = tv({
  base: 'flex flex-col space-y-s2',
  variants: {
    topSpacing: {
      s0: 'pt-s0',
      s4: 'pt-s4',
      s6: 'pt-s6',
      s9: 'pt-s9',
    },
    alignment: {
      center: 'items-center text-center',
      left: 'items-start text-left',
    },
  },
})

type HgCaptionVariants = VariantProps<typeof textWrapper>

export type HgCaptionProps = {
  iconType?: HgIconType | null
  title: string | React.ReactNode
  body: string | React.ReactNode
  titleTag?: TextTagProps['tag']
} & HgCaptionVariants

const HgCaption = ({
  iconType = 'sparkles',
  title,
  body,
  titleTag,
  topSpacing = 's0',
  alignment = 'left',
}: HgCaptionProps) => {
  return (
    <div className={textWrapper({topSpacing, alignment})}>
      {iconType ? (
        <HgIcon className="text-icon-default" iconType={iconType} size="large" />
      ) : null}

      <div>
        <TextTag tag={titleTag} className="text-text-default arcadia-heading-9">
          {title}
        </TextTag>
        <p className="text-text-subdued arcadia-body-2">{body}</p>
      </div>
    </div>
  )
}

export default HgCaption
