import {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {type OmitStrict} from 'type-zoo'
import {cn} from '~/design-system/utils'

type StatisticProps = {
  number: ReactNode
  unit?: ReactNode
  blurb: ReactNode
  className?: string
}

export const HgArticleStatistic = ({
  number,
  unit,
  blurb,
  className,
}: StatisticProps) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <p className="text-text-default arcadia-display-5">
        {number}
        {unit && (
          <span className="ms-s1 text-text-subdued arcadia-subheading-1">
            {unit}
          </span>
        )}
      </p>
      <p className="text-text-subdued arcadia-body-2">{blurb}</p>
    </div>
  )
}

type HgArticleWYSIWYGStatisticProps = {
  statistics: Array<OmitStrict<StatisticProps, 'className'>>
  className?: string
}

const statisticVariant = tv({
  base: 'col-span-full grid gap-x-s4 gap-y-s4 border-y border-solid border-border-frosted py-s4 md:grid-cols-[repeat(auto-fit,minmax(160px,1fr))]',
  variants: {
    numberOfStatisticColumns: {
      1: 'md:col-span-8 md:col-start-3 lg:col-start-5',
      2: 'md:col-span-8 md:col-start-3 lg:col-start-5',
      3: 'lg:col-span-12 lg:col-start-3',
      4: '3xl:col-span-12 3xl:col-start-3',
    },
  },
})

export const HgArticleWYSIWYGStatistic = ({
  statistics,
  className,
}: HgArticleWYSIWYGStatisticProps) => {
  const numberOfStatisticColumns = Math.min(statistics.length, 4) as VariantProps<
    typeof statisticVariant
  >['numberOfStatisticColumns']

  return (
    <div className={statisticVariant({numberOfStatisticColumns, className})}>
      {statistics.map((statistic, index) => (
        <HgArticleStatistic key={index} {...statistic} />
      ))}
    </div>
  )
}
