'use client'
import {
  type EmblaCarouselType as CarouselApi,
  type EmblaOptionsType as CarouselOptions,
  type EmblaPluginType as CarouselPlugin,
} from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import {
  type ComponentPropsWithRef,
  createContext,
  type KeyboardEvent,
  use,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {cn} from '~/design-system/utils'

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin[]
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
  autoplay?: boolean
  /**
   * How often to cycle the carousel in milliseconds.
   */
  autoplayDelay?: number
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  scrollTo: (index: number) => void
  canScrollPrev: boolean
  canScrollNext: boolean
  currentIndex: number
  isUsingKeyboardNavigation: boolean
} & CarouselProps

const CarouselContext = createContext<CarouselContextProps | null>(null)

export function useCarousel() {
  const context = use(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

const Carousel = ({
  ref,
  orientation = 'horizontal',
  opts,
  setApi,
  plugins = [],
  className,
  children,
  autoplay,
  autoplayDelay = 4000,
  ...props
}: CarouselProps & ComponentPropsWithRef<'div'>) => {
  const [carouselRef, api] = useEmblaCarousel(
    {
      ...opts,
      axis: orientation === 'horizontal' ? 'x' : 'y',
    },
    [
      ...(autoplay
        ? [
            Autoplay({
              delay: autoplayDelay,
            }),
          ]
        : []),
      ...plugins,
    ]
  )
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isUsingKeyboardNavigation, setIsUsingKeyboardNavigation] = useState(false)

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) {
      return
    }

    setCanScrollPrev(api.canScrollPrev())
    setCanScrollNext(api.canScrollNext())
    setCurrentIndex(api.selectedScrollSnap())
  }, [])

  const scrollPrev = useCallback(() => {
    if (!api) {
      return
    }
    api.scrollPrev()
  }, [api])

  const scrollNext = useCallback(() => {
    if (!api) {
      return
    }
    api.scrollNext()
  }, [api])

  const scrollTo = useCallback(
    (index: number) => {
      if (!api) {
        return
      }
      api.scrollTo(index)
    },
    [api]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        scrollPrev()
        api?.plugins().autoplay?.stop()
        setIsUsingKeyboardNavigation(true)
      } else if (event.key === 'ArrowRight') {
        event.preventDefault()
        scrollNext()
        api?.plugins().autoplay?.stop()
        setIsUsingKeyboardNavigation(true)
      }
    },
    [scrollPrev, api, scrollNext]
  )

  useEffect(() => {
    if (!api || !setApi) {
      return
    }

    setApi(api)
  }, [api, setApi])

  useEffect(() => {
    if (!api) {
      return
    }

    onSelect(api)
    api.on('reInit', onSelect)
    api.on('select', onSelect)

    return () => {
      api?.off('select', onSelect)
    }
  }, [api, onSelect])

  return (
    <CarouselContext
      value={{
        carouselRef,
        api,
        opts,
        orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
        scrollPrev,
        scrollNext,
        scrollTo,
        canScrollPrev,
        canScrollNext,
        currentIndex,
        isUsingKeyboardNavigation,
      }}
    >
      <div
        ref={ref}
        onKeyDownCapture={handleKeyDown}
        onBlur={() => {
          setIsUsingKeyboardNavigation(false)
        }}
        className={cn('relative', className)}
        role="region"
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext>
  )
}

const CarouselContent = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'div'>) => {
  const {carouselRef, orientation} = useCarousel()

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          'flex',
          orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
          className
        )}
        {...props}
      />
    </div>
  )
}

const CarouselItem = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'div'>) => {
  const {orientation} = useCarousel()

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'min-w-0 shrink-0 grow-0 basis-full',
        orientation === 'horizontal' ? 'pl-4' : 'pt-4',
        className
      )}
      {...props}
    />
  )
}

export {Carousel, CarouselContent, CarouselItem, type CarouselApi}
