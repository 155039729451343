'use client'

import {faChevronDown} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as React from 'react'
import {cn} from '~/design-system/utils'

const Accordion = AccordionPrimitive.Root

const AccordionItem = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof AccordionPrimitive.Item>) => (
  <AccordionPrimitive.Item ref={ref} className={className} {...props} />
)

const AccordionTrigger = ({
  ref,
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof AccordionPrimitive.Trigger>) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn('group flex flex-1 items-center justify-between', className)}
      {...props}
    >
      {children}
      <FontAwesomeIcon
        icon={faChevronDown}
        className="shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180"
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
)

const AccordionContent = ({
  ref,
  className,
  children,
  ...props
}: React.ComponentPropsWithRef<typeof AccordionPrimitive.Content>) => {
  const innerRef = React.useRef<HTMLDivElement>(null)

  React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement)

  React.useEffect(() => {
    const ref = innerRef.current

    if (!ref) return
    handleInert()

    function handleInert() {
      if (!ref) return
      // Despite being a valid, accepted HTML attribute, React types does not recognize `inert`
      // Moreover, despite HTML recognizing this as a boolean attribute, React won't accept it unless it's a string
      // Support has been added to react-dom@experimental and Next canary, but neither are live yet.
      const state = ref.getAttribute('data-state')
      if (state === 'closed') {
        ref.setAttribute('inert', '')
      } else {
        ref.removeAttribute('inert')
      }
    }

    ref.addEventListener('transitionstart', handleInert)
    return () => {
      ref.removeEventListener('transitionstart', handleInert)
    }
  }, [])

  return (
    <AccordionPrimitive.Content ref={innerRef} className={className} {...props}>
      {children}
    </AccordionPrimitive.Content>
  )
}

export {Accordion, AccordionContent, AccordionItem, AccordionTrigger}
