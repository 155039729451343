import {tv} from 'tailwind-variants'

export const hgArticleListingSplitVariants = tv({
  slots: {
    wrapper: 'w-full',
    innerContainer: 'grid w-full grid-cols-1 gap-s4',
    textContainer: 'flex flex-col xl:max-w-[688px]',
    imageWrapper: 'h-full w-full',
  },
  variants: {
    splitRatio: {
      '75/25': {
        innerContainer: 'md:grid-cols-[3fr_1fr]',
      },
      '50/50': {
        innerContainer: 'md:grid-cols-[1fr_1fr]',
      },
    },
    imageAlignment: {
      left: {},
      right: {
        innerContainer: '[&>*:first-child]:md:order-2 [&>*:last-child]:md:order-1',
      },
    },
  },
  compoundVariants: [
    {
      imageAlignment: 'right',
      splitRatio: '75/25',
      class: {
        innerContainer: 'md:grid-cols-[1fr_3fr]',
      },
    },
    {
      imageAlignment: 'right',
      splitRatio: '50/50',
      class: {
        innerContainer: 'md:grid-cols-[auto_1fr]',
      },
    },
  ],
})
