'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {usePathname} from 'next/navigation'
import {type ReactNode} from 'react'
import {LinkWrapper} from '~/design-system/foundations'
import HgIcon from '../HgIcon'
import {textLink} from '../HgTextLink'
import {type HgNavigationBaseLinkProps} from './types'

export type HgNavigationLinkProps = HgNavigationBaseLinkProps & {
  description?: string | ReactNode
  closeNav?: () => void
}

export const HgNavigationLink = ({
  ref,
  href,
  label,
  description,
  closeNav,
  ...linkProps
}: HgNavigationLinkProps) => {
  const pathname = usePathname()
  const isActive = href === pathname
  const {linkWrapper, linkChildren, icon} = textLink({
    interaction: 'revealIcon',
    hasIcon: true,
  })

  return (
    <div className="flex flex-col items-start justify-start space-y-s2">
      <NavigationMenu.Link onClick={closeNav} asChild active={isActive}>
        <LinkWrapper
          {...linkProps}
          href={href}
          className="group flex flex-col gap-s1 rounded outline-2 outline-offset-4 outline-border-focus focus-visible:outline"
          ref={ref}
        >
          <div className={linkWrapper({className: 'arcadia-ui-1'})}>
            <p className={linkChildren()}>{label}</p>
            <HgIcon iconType="arrow-right" size="medium" className={icon()} />
          </div>
          {description && (
            <p className="peer pb-8 text-text-subdued arcadia-body-3">
              {description}
            </p>
          )}
        </LinkWrapper>
      </NavigationMenu.Link>
    </div>
  )
}
