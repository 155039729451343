import {type PropsWithChildren} from 'react'
import {gridItem, TextTag} from '~/design-system/foundations'
import {tv, type VariantProps} from 'tailwind-variants'
import {Slot} from '@radix-ui/react-slot'
import {cn} from '~/design-system/utils'
import {type ExtractStrict} from 'type-zoo'
import {HgTextLink} from '~/design-system/hg/components'

const headingVariants = tv({
  base: 'text-text-default',
  variants: {
    tag: {
      h2: 'arcadia-subheading-2',
      h3: 'arcadia-subheading-5',
      h4: 'arcadia-heading-9',
    },
  },
})

type Tag = NonNullable<VariantProps<typeof headingVariants>['tag']>

type HgLinkableHeadingTag = ExtractStrict<Tag, 'h2'>

type HgLinkableHeadingTagProps = {
  tag: HgLinkableHeadingTag
  id: string
}

type HgStandardHeadingTagProps = {
  tag: Exclude<Tag, HgLinkableHeadingTag>
}

type HgHeadingSpecificProps = HgLinkableHeadingTagProps | HgStandardHeadingTagProps

export type HgHeadingTagProps = PropsWithChildren<{
  className?: string
}> &
  HgHeadingSpecificProps

export const HgHeadingTag = ({className, children, ...props}: HgHeadingTagProps) => {
  return 'id' in props ? (
    <HgTextLink
      className={cn(className, 'inline-flex')}
      href={`#${props.id}`}
      variant="revealUnderline"
    >
      <TextTag
        tag={props.tag}
        className={headingVariants({
          tag: props.tag,
          className: 'scroll-mt-blog-navbars-height',
        })}
        id={props.id}
      >
        {children}
      </TextTag>
    </HgTextLink>
  ) : (
    <TextTag
      className={headingVariants({tag: props.tag, className})}
      tag={props.tag}
    >
      {children}
    </TextTag>
  )
}

export const HgArticleWYSIWYGHeading = (props: HgHeadingTagProps) => {
  return (
    <Slot className={gridItem({size: 'heading', className: props.className})}>
      <HgHeadingTag {...props} />
    </Slot>
  )
}
