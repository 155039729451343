import HgArticleListingIndex from './_components/Index'
import HgArticleListingMini from './_components/Mini'
import HgArticleListingSplit from './_components/Split'
import HgArticleListingStacked from './_components/Stacked'
import HgArticleListingTextOnly from './_components/TextOnly'
import {type HgArticleListingProps} from './types'

export default function HgArticleListing(props: HgArticleListingProps) {
  switch (props.variant) {
    case 'mini':
      return <HgArticleListingMini {...props} />
    case 'textOnly':
      return <HgArticleListingTextOnly {...props} />
    case 'split':
      return <HgArticleListingSplit {...props} />
    case 'index':
      return <HgArticleListingIndex {...props} />
    case 'stacked':
      return <HgArticleListingStacked {...props} />
  }
}
