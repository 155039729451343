import NextLink, {type LinkProps as NextLinkProps} from 'next/link'
import {type PropsWithChildren} from 'react'
import {type OmitStrict} from 'type-zoo'

export type LinkWrapperProps = NextLinkProps &
  OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'color'> & {
    newTab?: boolean
    ref?: React.RefObject<HTMLAnchorElement>
  }
const LinkWrapper = ({
  ref,
  children,
  href,
  newTab,
  rel,
  target,
  className,
  prefetch,
  ...rest
}: PropsWithChildren<LinkWrapperProps>) => {
  return (
    <NextLink
      ref={ref}
      {...rest}
      href={href}
      prefetch={prefetch}
      className={className}
      target={newTab ? '_blank' : target}
      rel={newTab ? `noopener noreferrer${rel ? ` ${rel}` : ''}` : rel}
    >
      {children}
    </NextLink>
  )
}

export default LinkWrapper
