import * as React from 'react'
import {cn} from '~/design-system/utils'

type TableProps = {
  classNames?: {
    wrapper?: string
    table?: string
  }
} & Omit<React.ComponentPropsWithRef<'table'>, 'className'>

const Table = ({ref, classNames, ...props}: TableProps) => (
  <div className={cn('relative w-full overflow-auto', classNames?.wrapper)}>
    <table
      ref={ref}
      className={cn(
        'w-full caption-bottom border-t border-border-default text-sm',
        classNames?.table
      )}
      {...props}
    />
  </div>
)

const TableHeader = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'thead'>) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
)

const TableBody = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'tbody'>) => (
  <tbody ref={ref} className={className} {...props} />
)

const TableFooter = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'tfoot'>) => (
  <tfoot ref={ref} className={cn('[&>tr]:last:border-b-0', className)} {...props} />
)

const TableRow = ({ref, className, ...props}: React.ComponentPropsWithRef<'tr'>) => (
  <tr
    ref={ref}
    className={cn(
      'data-[state=selected]:bg-muted border-b border-border-subdued transition-colors',
      className
    )}
    {...props}
  />
)

const TableHead = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'th'>) => (
  <th
    ref={ref}
    className={cn(
      'text-left [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  />
)

const TableCell = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'td'>) => (
  <td
    ref={ref}
    className={cn(
      'p-2 align-top [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  />
)

const TableCaption = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'caption'>) => (
  <caption ref={ref} className={className} {...props} />
)

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
}
