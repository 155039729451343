'use client'
import {type ReactNode, useState, useId} from 'react'
import {tv} from 'tailwind-variants'
import {cn} from '~/design-system/utils'
import {gridItem} from '~/design-system/foundations'
import {
  HgEmailCapture,
  type HgEmailFormData,
  HgCheckboxInput,
} from '~/design-system/hg/components'
import {type NewsletterList} from '~/mercuryWebBackend/endpoints/NewsletterSubscribe'
import {getPromoModuleSize} from '~/design-system/hg/components/HgArticlePromos/utils'

export type HgBlogNewsletterList = NewsletterList | 'meridian'

type HgNewsletter = {
  id: string
  label: string
  description?: ReactNode
  list: HgBlogNewsletterList
}

export type HgPromoNewsletterProps = {
  heading: ReactNode
  body?: ReactNode
  newsletters: HgNewsletter[]
  successMessage: ReactNode
  onSubmit: (
    selectedNewsletters: HgBlogNewsletterList[]
  ) => (formData: HgEmailFormData) => Promise<void>
  className?: string
  width?: 'article' | 'inset' | 'fullWidth'
}

const promoNewsletterVariants = tv({
  slots: {
    wrapper: 'flex flex-col bg-background-secondary px-s5',
    textContainer: 'flex flex-col gap-s2',
    subscribeContainer: '',
    heading: 'text-text-default',
    body: 'text-text-subdued',
    checkboxContainer: '',
  },
  variants: {
    multipleNewsletters: {
      true: {
        wrapper: 'items-start gap-s4',
        subscribeContainer:
          'flex w-full flex-col gap-s6 border-t border-border-frosted',
      },
      false: {
        wrapper: 'items-center py-s7',
        textContainer: 'max-w-[688px] items-center text-center',
      },
    },
    width: {
      article: {
        heading: 'arcadia-subheading-5',
        body: 'arcadia-body-2',
      },
      inset: {},
      fullWidth: {
        wrapper: 'col-start-1',
      },
    },
  },
  compoundVariants: [
    {
      width: ['inset', 'fullWidth'],
      class: {
        heading: 'arcadia-subheading-3',
        body: 'arcadia-body-1',
      },
    },
    {
      width: 'article',
      multipleNewsletters: false,
      class: {
        wrapper: 'gap-s6',
      },
    },
    {
      width: ['inset', 'fullWidth'],
      multipleNewsletters: false,
      class: {
        wrapper: 'gap-s4',
      },
    },
    {
      width: 'article',
      multipleNewsletters: true,
      class: {
        wrapper: 'py-s5 2xl:flex-row',
        subscribeContainer:
          'pb-s4 pt-s4 2xl:basis-1/2 2xl:border-l 2xl:border-t-0 2xl:pl-s4 2xl:pt-0',
        textContainer: '2xl:basis-1/2',
      },
    },
    {
      width: 'inset',
      multipleNewsletters: true,
      class: {
        wrapper: 'px-s7 py-s7 xl:flex-row',
        subscribeContainer:
          'pb-s0 pt-s0 xl:basis-1/2 xl:border-l xl:border-t-0 xl:py-0 xl:pl-s4 3xl:pb-s5',
        textContainer: 'xl:basis-1/2',
      },
    },
    {
      width: 'fullWidth',
      multipleNewsletters: true,
      class: {
        wrapper: 'py-s7 md:items-center',
        subscribeContainer: 'max-w-[688px] 3xl:pb-s5',
        textContainer: 'w-full max-w-[688px]',
        checkboxContainer: 'grid grid-cols-1 gap-x-s3 md:grid-cols-2 md:gap-y-s6',
      },
    },
  ],
})

const HgPromoNewsletter = ({
  heading,
  body,
  newsletters,
  successMessage,
  onSubmit,
  className,
  width = 'article',
}: HgPromoNewsletterProps) => {
  const [selectedNewsletters, setSelectedNewsletters] = useState<
    HgBlogNewsletterList[]
  >(newsletters.length === 1 ? [newsletters[0].list] : [])
  const formId = useId()
  const checkboxGroupId = useId()

  const {
    wrapper,
    textContainer,
    subscribeContainer,
    heading: headingVariant,
    body: bodyVariant,
    checkboxContainer,
  } = promoNewsletterVariants({
    multipleNewsletters: newsletters.length > 1,
    width,
  })

  const handleSubmit = async (formData: HgEmailFormData) => {
    // custom error handling since checkboxes live outside the form tag
    if (newsletters.length > 1 && selectedNewsletters.length === 0) {
      throw new Error('Please select at least one newsletter')
    }
    await onSubmit(selectedNewsletters)(formData)
  }

  return (
    <div
      className={gridItem({
        size: getPromoModuleSize(width),
        class: cn(wrapper(), className),
      })}
    >
      <div className={textContainer()}>
        <p className={headingVariant()}>{heading}</p>
        {body && <p className={bodyVariant()}>{body}</p>}
      </div>
      <div className={subscribeContainer()}>
        {newsletters.length > 1 && (
          <fieldset aria-controls={formId}>
            <legend id={checkboxGroupId} className="sr-only">
              Select at least one newsletter to subscribe
            </legend>
            <div className={checkboxContainer()}>
              {newsletters.map(newsletter => (
                <HgCheckboxInput
                  key={newsletter.id}
                  label=""
                  options={[
                    {
                      'label': newsletter.label,
                      'value': newsletter.list,
                      'checked': selectedNewsletters.includes(newsletter.list),
                      'onCheckedChange': checked => {
                        setSelectedNewsletters(prev =>
                          checked
                            ? [...prev, newsletter.list]
                            : prev.filter(l => l !== newsletter.list)
                        )
                      },
                      'aria-controls': formId,
                      'form': formId,
                    },
                  ]}
                  secondaryLabel={newsletter.description}
                  classNames={{
                    optionsContainer: '[&_label]:text-text-default',
                    secondaryLabel: 'ml-[28px]',
                  }}
                />
              ))}
            </div>
          </fieldset>
        )}
        <HgEmailCapture
          variant="secondary"
          labelText="Enter your email"
          submitButtonText="Submit"
          successMessage={successMessage}
          formProps={{
            'id': formId,
            'data-analytics-event-name': 'subscribe',
            'data-analytics-disable-click-listener': 'true',
            'onSubmit': handleSubmit,
            'aria-describedby': newsletters.length > 1 ? checkboxGroupId : undefined,
          }}
          classNames={{formItemWrapper: 'max-w-[448px]', formItem: 'min-w-min'}}
        />
      </div>
    </div>
  )
}

export default HgPromoNewsletter
