import HgSubsectionTitle from '../HgSubsectionTitle'
import HgArticleListingStacked from '../HgArticleListingStacked'
import {type HgArticleRecirculationProps} from './types'

const HgArticleRecirculation = ({
  articles,
  ...subsectionProps
}: HgArticleRecirculationProps) => {
  return (
    <>
      <HgSubsectionTitle {...subsectionProps} layout="fullWidth" />
      <HgArticleListingStacked
        aspectRatio="16:9"
        showListingSubheading={true}
        width="fullWidth"
        alignment="left"
        articles={articles}
      />
    </>
  )
}

export default HgArticleRecirculation
