'use client'

import {Slot} from '@radix-ui/react-slot'
import React, {useState} from 'react'
import {tv} from 'tailwind-variants'
import {LinkWrapper} from '~/design-system/foundations'
import Banking from './images/banking.svg'

const ELLIPSE_ID_PREFIX = 'ellipse-'

export const graphic = tv({
  // This is necessary to avoid bug with how firefox updates fill color w/ <use /> elements - see more here:
  // https://stackoverflow.com/questions/27866893/svg-fill-not-being-applied-in-firefox
  base: '[&_svg_>_use]:fill-border-default',
  slots: {
    productLink:
      'group peer fill-transparent-transparent outline-none [&>circle]:focus-visible:stroke-border-focus [&>circle]:focus-visible:stroke-1',
    productNumber: 'fill-text-subdued text-center font-sans font-medium',
    productDescription:
      'group-hover:transition-colors group-hover:duration-300 group-hover:ease-out [&_*]:!fill-text-subdued [&_*]:group-hover:!fill-text-default',
    productEllipse:
      'fill-border-subdued stroke-0 peer-hover:fill-border-emphasized peer-hover:stroke-border-emphasized peer-hover:stroke-[0.25] peer-hover:transition-colors peer-hover:duration-300 peer-hover:ease-out',
    outerCircle: 'fill-border-subdued',
    figureLabel: 'fill-text-subdued text-center font-sans font-medium',
    figureText: 'fill-text-default text-center font-sans font-medium',
  },
})
type ProductOfferingProps = {
  productOfferingHref: string
  productOfferingLabel: string
  productOfferingTextGraphic: React.ReactNode
  productOfferingNumberXCoordinate?: number
  productOfferingNumberYCoordinate?: number
  analyticsEventName?: string
}

type EllipseProps = {
  ellipseNumber: number
  setTopEllipseNumber: (ellipseNumber: number | null) => void
} & ProductOfferingProps

export type ProductGraphicProps = {
  productOffering2: ProductOfferingProps
  productOffering3: ProductOfferingProps
  productOffering4: ProductOfferingProps
  productOffering5: ProductOfferingProps
  productOffering6: ProductOfferingProps
  productOffering7: ProductOfferingProps
}

const Ellipse1 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle id="circle-center" cx="136" cy="136" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x="133.83"
          y="128.7"
          className={productNumber()}
          fontSize={4.5}
        >
          01
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M136,100.5c19.6,0,35.5,15.9,35.5,35.5s-15.9,35.5-35.5,35.5s-35.5-15.9-35.5-35.5S116.4,100.5,136,100.5 M136,100 c-19.9,0-36,16.1-36,36s16.1,36,36,36s36-16.1,36-36S155.9,100,136,100L136,100z"
        />
      </svg>
    </g>
  )
}

const Ellipse2 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="136" cy="40" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '133.3'}
          y={productOfferingNumberYCoordinate || '27.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          02
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M136,4.5c30.6,0,55.5,37.46,55.5,83.5s-24.9,83.5-55.5,83.5S80.5,134.04,80.5,88S105.4,4.5,136,4.5 M136,4 c-30.93,0-56,37.61-56,84s25.07,84,56,84s56-37.61,56-84S166.93,4,136,4L136,4z"
        />
      </svg>
    </g>
  )
}

const Ellipse3 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="219.14" cy="88" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '216.35'}
          y={productOfferingNumberYCoordinate || '80.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          03
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M204,48.34c21.12,0,37.84,7.98,45.88,21.91c15.3,26.5-4.69,66.79-44.56,89.81c-17.42,10.06-36.66,15.59-54.18,15.59 c-21.12,0-37.85-7.98-45.89-21.91c-7.39-12.8-6.85-29.18,1.52-46.12c8.41-17.01,23.69-32.52,43.04-43.69 C167.23,53.88,186.47,48.34,204,48.34L204,48.34 M204,47.84c-17.01,0-36.23,5.15-54.43,15.66c-40.18,23.2-60.21,63.71-44.75,90.5 c8.46,14.65,25.78,22.16,46.32,22.16c17.01,0,36.23-5.15,54.43-15.66c40.18-23.2,60.21-63.71,44.75-90.5 C241.86,55.35,224.53,47.84,204,47.84L204,47.84z"
        />
      </svg>
    </g>
  )
}

const Ellipse4 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="219.14" cy="184" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '216.38'}
          y={productOfferingNumberYCoordinate || '176.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          04
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M151.14,95.84v0.5h0c17.52,0,36.76,5.54,54.17,15.59c39.87,23.02,59.86,63.31,44.56,89.81 c-8.04,13.92-24.76,21.91-45.89,21.91c-17.52,0-36.76-5.54-54.18-15.59c-19.35-11.17-34.63-26.69-43.04-43.69 c-8.37-16.94-8.92-33.32-1.52-46.12c8.04-13.92,24.76-21.91,45.89-21.91L151.14,95.84 M151.14,95.84 c-20.54,0-37.86,7.51-46.32,22.16c-15.46,26.78,4.57,67.3,44.75,90.5c18.2,10.51,37.42,15.66,54.43,15.66 c20.54,0,37.86-7.51,46.32-22.16c15.46-26.78-4.57-67.3-44.75-90.5C187.37,100.99,168.15,95.84,151.14,95.84L151.14,95.84z"
        />
      </svg>
    </g>
  )
}

const Ellipse5 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="136" cy="232" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '133.3'}
          y={productOfferingNumberYCoordinate || '219.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          05
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id="ellipse-5"
          d="M136,100.5c30.6,0,55.5,37.46,55.5,83.5s-24.9,83.5-55.5,83.5S80.5,230.04,80.5,184S105.4,100.5,136,100.5 M136,100 c-30.93,0-56,37.61-56,84s25.07,84,56,84s56-37.61,56-84S166.93,100,136,100L136,100z"
        />
      </svg>
    </g>
  )
}

const Ellipse6 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="52.86" cy="184" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '50.26'}
          y={productOfferingNumberYCoordinate || '171.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          06
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M120.86,96.34c21.12,0,37.84,7.98,45.88,21.91c7.39,12.8,6.85,29.18-1.52,46.12c-8.41,17.01-23.69,32.52-43.04,43.69 c-17.42,10.06-36.66,15.59-54.18,15.59c-21.12,0-37.85-7.98-45.89-21.91c-7.39-12.8-6.85-29.18,1.52-46.12 c8.41-17.01,23.69-32.52,43.04-43.69C84.09,101.88,103.34,96.34,120.86,96.34L120.86,96.34 M120.86,95.84 c-17.01,0-36.23,5.15-54.43,15.66C26.25,134.7,6.22,175.22,21.68,202c8.46,14.65,25.78,22.16,46.32,22.16 c17.01,0,36.23-5.15,54.43-15.66c40.18-23.2,60.21-63.71,44.75-90.5C158.72,103.35,141.4,95.84,120.86,95.84L120.86,95.84z"
        />
      </svg>
    </g>
  )
}

const Ellipse7 = ({
  productOfferingHref,
  productOfferingLabel,
  productOfferingTextGraphic,
  productOfferingNumberXCoordinate,
  productOfferingNumberYCoordinate,
  analyticsEventName,
  ellipseNumber,
  setTopEllipseNumber,
}: EllipseProps) => {
  const {productLink, productNumber, productDescription, productEllipse} = graphic()

  return (
    <g id={`ellipse-group-${ellipseNumber}`}>
      <LinkWrapper
        href={productOfferingHref}
        data-analytics-event-name={analyticsEventName}
        className={productLink()}
        onPointerEnter={() => {
          setTopEllipseNumber(ellipseNumber)
        }}
        onPointerLeave={() => {
          setTopEllipseNumber(null)
        }}
      >
        <circle cx="52.86" cy="88" r="32" />
        <text
          textRendering="optimizeLegibility"
          transform="scale(1, 1)"
          x={productOfferingNumberXCoordinate || '50.31'}
          y={productOfferingNumberYCoordinate || '80.7'}
          className={productNumber()}
          fontSize={4.5}
        >
          07
        </text>
        {productOfferingLabel}
        <Slot className={productDescription()}>{productOfferingTextGraphic}</Slot>
      </LinkWrapper>
      <svg className={productEllipse()}>
        <path
          id={`${ELLIPSE_ID_PREFIX}${ellipseNumber}`}
          d="M68.01,47.84v0.5h0c17.52,0,36.76,5.54,54.17,15.59c19.35,11.17,34.63,26.69,43.04,43.69 c8.37,16.94,8.92,33.32,1.52,46.12c-8.04,13.92-24.76,21.91-45.89,21.91c-17.52,0-36.76-5.54-54.18-15.59 c-19.35-11.17-34.63-26.69-43.04-43.69c-8.37-16.94-8.92-33.32-1.52-46.12C30.16,56.33,46.88,48.34,68,48.34L68.01,47.84 M68,47.84c-20.54,0-37.86,7.51-46.32,22.16c-15.46,26.78,4.57,67.3,44.75,90.5c18.2,10.51,37.42,15.66,54.43,15.66 c20.54,0,37.86-7.51,46.32-22.16c15.46-26.78-4.57-67.3-44.75-90.5C104.23,52.99,85.02,47.84,68,47.84L68,47.84z"
        />
      </svg>
    </g>
  )
}

const ProductSummaryGraphic = ({
  productOffering2,
  productOffering3,
  productOffering4,
  productOffering5,
  productOffering6,
  productOffering7,
}: ProductGraphicProps) => {
  const [topEllipseNumber, setTopEllipseNumber] = useState<number | null>(null)
  const {base, outerCircle, figureLabel, figureText} = graphic()

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="product-summary"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 272 272"
        className={base()}
        shapeRendering="geometricPrecision"
      >
        <path
          id="outer_circle"
          className={outerCircle()}
          d="M136,0.5c36.19,0,70.22,14.09,95.81,39.69C257.41,65.78,271.5,99.81,271.5,136s-14.09,70.22-39.69,95.81 c-25.59,25.59-59.62,39.69-95.81,39.69s-70.22-14.09-95.81-39.69C14.59,206.22,0.5,172.19,0.5,136s14.09-70.22,39.69-95.81 C65.78,14.59,99.81,0.5,136,0.5 M136,0C60.89,0,0,60.89,0,136s60.89,136,136,136s136-60.89,136-136S211.11,0,136,0L136,0z"
        />
        <g id="mercury_graphic" role="img">
          <title>Figure A, Mercury</title>
          <text
            textRendering="optimizeLegibility"
            transform="scale(1, 1)"
            x="247"
            y="260.77"
            className={figureLabel({class: 'text-center font-sans'})}
            fontSize={4.7}
            fontFamily="Arcadia"
          >
            Figure A
          </text>
          <text
            textRendering="optimizeLegibility"
            transform="scale(1, 1)"
            x="240.3"
            y="270"
            className={figureText()}
            fontSize={8}
            fontFamily="Arcadia"
          >
            Mercury
          </text>
        </g>
        <Ellipse1
          ellipseNumber={1}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref="https://mercury.com/bank-accounts"
          productOfferingLabel="Banking"
          productOfferingTextGraphic={<Banking />}
          analyticsEventName="homepage-bank-accounts"
        />
        <Ellipse2
          ellipseNumber={2}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering2.productOfferingHref}
          productOfferingLabel={productOffering2.productOfferingLabel}
          productOfferingTextGraphic={productOffering2.productOfferingTextGraphic}
          analyticsEventName={productOffering2.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering2.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering2.productOfferingNumberYCoordinate
          }
        />
        <Ellipse3
          ellipseNumber={3}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering3.productOfferingHref}
          productOfferingLabel={productOffering3.productOfferingLabel}
          productOfferingTextGraphic={productOffering3.productOfferingTextGraphic}
          analyticsEventName={productOffering3.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering3.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering3.productOfferingNumberYCoordinate
          }
        />
        <Ellipse4
          ellipseNumber={4}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering4.productOfferingHref}
          productOfferingLabel={productOffering4.productOfferingLabel}
          productOfferingTextGraphic={productOffering4.productOfferingTextGraphic}
          analyticsEventName={productOffering4.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering4.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering4.productOfferingNumberYCoordinate
          }
        />
        <Ellipse5
          ellipseNumber={5}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering5.productOfferingHref}
          productOfferingLabel={productOffering5.productOfferingLabel}
          productOfferingTextGraphic={productOffering5.productOfferingTextGraphic}
          analyticsEventName={productOffering5.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering5.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering5.productOfferingNumberYCoordinate
          }
        />
        <Ellipse6
          ellipseNumber={6}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering6.productOfferingHref}
          productOfferingLabel={productOffering6.productOfferingLabel}
          productOfferingTextGraphic={productOffering6.productOfferingTextGraphic}
          analyticsEventName={productOffering6.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering6.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering6.productOfferingNumberYCoordinate
          }
        />
        <Ellipse7
          ellipseNumber={7}
          setTopEllipseNumber={setTopEllipseNumber}
          productOfferingHref={productOffering7.productOfferingHref}
          productOfferingLabel={productOffering7.productOfferingLabel}
          productOfferingTextGraphic={productOffering7.productOfferingTextGraphic}
          analyticsEventName={productOffering7.analyticsEventName}
          productOfferingNumberXCoordinate={
            productOffering7.productOfferingNumberXCoordinate
          }
          productOfferingNumberYCoordinate={
            productOffering7.productOfferingNumberYCoordinate
          }
        />
        <svg>
          <use
            href={
              topEllipseNumber
                ? `#${ELLIPSE_ID_PREFIX}${topEllipseNumber}`
                : undefined
            }
          />
        </svg>
      </svg>
    </>
  )
}

export default ProductSummaryGraphic
