import {type ReactNode} from 'react'
import {cn} from '~/design-system/utils'
import HgIcon, {type HgIconType} from '../../HgIcon'

export type HgComposableDetailsListItemProps = {
  iconType: HgIconType
  text: ReactNode
}

type HgComposableDetailsListProps = {
  title?: ReactNode
  listItems: HgComposableDetailsListItemProps[]
  className?: string
}

const HgComposableDetailsList = ({
  title,
  listItems,
  className,
}: HgComposableDetailsListProps) => (
  <div className={cn('flex flex-col space-y-s2', className)}>
    {title && <h3 className="text-text-default arcadia-heading-9">{title}</h3>}
    <ul>
      {listItems.map((item, index) => (
        <li key={index} className="flex items-center space-x-s2 text-text-subdued">
          <HgIcon iconType={item.iconType} size="small" />
          <span className="arcadia-body-2">{item.text}</span>
        </li>
      ))}
    </ul>
  </div>
)

export default HgComposableDetailsList
