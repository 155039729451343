/* eslint-disable react/no-unknown-property */
import faceVert from '../shaders/face.vert'
import lineVert from '../shaders/line.vert'
import advectionFrag from '../shaders/advection.frag'
import type * as THREE from 'three'
import {
  TIME_SCALE,
  type ShaderPassProps,
} from '~/design-system/hg/components/StableFluidBackground/types'
import {useFrame} from '@react-three/fiber'
import {useMemo} from 'react'

type AdvectionProps = {
  fboSize: THREE.Vector2
} & ShaderPassProps

export default function Advection({cellScale, fboSize, fbos}: AdvectionProps) {
  /**
   * Defines the x, y, and z vertices of a square in normalized device coordinates (NDC). In NDC, the visible area of the screen is a square that goes from -1 to 1 in both the x and y directions. The z coordinate is always 0 because we are working in 2D.
   *
   * The first pair (-1, -1, 0) and (-1, 1, 0) define the left edge of the square from bottom to top.
   * The second pair (-1, 1, 0) and (1, 1, 0) define the top edge from left to right.
   * The third pair (1, 1, 0) and (1, -1, 0) define the right edge from top to bottom.
   * The last pair (1, -1, 0) and (-1, -1, 0) define the bottom edge from right to left.
   *
   * This will determine where the fluid is allowed to flow.
   */
  const verticesBoundary = new Float32Array([
    // left
    -1, -1, 0, -1, 1, 0,

    // top
    -1, 1, 0, 1, 1, 0,

    // right
    1, 1, 0, 1, -1, 0,

    // bottom
    1, -1, 0, -1, -1, 0,
  ])

  useFrame(({gl, scene, camera}) => {
    gl.setRenderTarget(fbos.vel_1)
    gl.render(scene, camera)
    gl.setRenderTarget(null)
  })

  const uniforms = useMemo(
    () => ({
      u_boundarySpace: {
        value: cellScale,
      },
      u_px: {
        value: cellScale,
      },
      u_fboSize: {
        value: fboSize,
      },
      u_velocity: {
        value: fbos.vel_0?.texture,
      },
      u_dt: {
        value: TIME_SCALE,
      },
    }),
    [cellScale, fboSize, fbos.vel_0?.texture]
  )

  return (
    <>
      <mesh>
        <shaderMaterial
          uniforms={uniforms}
          fragmentShader={advectionFrag}
          vertexShader={faceVert}
        />
        <planeGeometry args={[2.0, 2.0]} />
      </mesh>
      <lineSegments>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            args={[verticesBoundary, 3]}
          />
        </bufferGeometry>
        <shaderMaterial
          vertexShader={lineVert}
          uniforms={uniforms}
          fragmentShader={advectionFrag}
        />
      </lineSegments>
    </>
  )
}
