'use client'

import * as SelectPrimitive from '@radix-ui/react-select'
import {cn} from '~/design-system/utils'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const SelectTrigger = ({
  ref,
  className,
  children,
  icon,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.Trigger> & {
  icon: React.ReactNode
}) => (
  <SelectPrimitive.Trigger ref={ref} className={className} {...props}>
    {children}
    <SelectPrimitive.Icon asChild>{icon}</SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
)

const SelectScrollUpButton = ({
  ref,
  className,
  icon,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.ScrollUpButton> & {
  icon: React.ReactNode
}) => (
  <SelectPrimitive.ScrollUpButton ref={ref} className={className} {...props}>
    {icon}
  </SelectPrimitive.ScrollUpButton>
)

const SelectScrollDownButton = ({
  ref,
  className,
  icon,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.ScrollDownButton> & {
  icon: React.ReactNode
}) => (
  <SelectPrimitive.ScrollDownButton ref={ref} className={className} {...props}>
    {icon}
  </SelectPrimitive.ScrollDownButton>
)

const SelectContent = ({
  ref,
  className,
  children,
  position,
  scrollUpButtonIcon,
  scrollDownButtonIcon,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.Content> & {
  scrollUpButtonIcon: React.ReactNode
  scrollDownButtonIcon: React.ReactNode
}) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn('z-10', className)}
      position={position}
      {...props}
    >
      <SelectScrollUpButton
        className="flex items-center justify-center"
        icon={scrollUpButtonIcon}
      />
      <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      <SelectScrollDownButton
        className="flex items-center justify-center"
        icon={scrollDownButtonIcon}
      />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
)

const SelectLabel = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.Label>) => (
  <SelectPrimitive.Label ref={ref} className={className} {...props} />
)

const SelectItem = ({
  ref,
  className,
  children,
  indicatorIcon,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.Item> & {
  indicatorIcon?: React.ReactNode
}) => (
  <SelectPrimitive.Item ref={ref} className={className} {...props}>
    {indicatorIcon && (
      <SelectPrimitive.ItemIndicator asChild>
        {indicatorIcon}
      </SelectPrimitive.ItemIndicator>
    )}
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
)

const SelectSeparator = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<typeof SelectPrimitive.Separator>) => (
  <SelectPrimitive.Separator ref={ref} className={className} {...props} />
)

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
}
