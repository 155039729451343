import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {gridItem} from '~/design-system/foundations'

const orderedListVariants = tv({
  base: 'list-outside ps-[1em] text-text-default tiempos-body-2 [&_li_+_li]:mt-s2 [&_li_>_ol]:mt-s2',
  variants: {
    nestedLevel: {
      0: 'list-decimal',
      1: 'list-[lower-alpha]',
      2: 'list-[lower-roman]',
    },
  },
})

type HgOrderedListNestedLevel = NonNullable<
  VariantProps<typeof orderedListVariants>['nestedLevel']
>

export type HgOrderedListProps = PropsWithChildren<{
  className?: string
  nestedLevel: HgOrderedListNestedLevel
}>

export const HgOrderedList = ({
  children,
  className,
  nestedLevel,
}: HgOrderedListProps) => {
  return (
    <ol className={orderedListVariants({nestedLevel, className})}>{children}</ol>
  )
}

export const HgArticleWYSIWYGOrderedList = ({
  nestedLevel,
  className,
  ...props
}: HgOrderedListProps) => {
  return (
    <HgOrderedList
      {...props}
      nestedLevel={nestedLevel || 0}
      className={gridItem({size: 'heading', className})}
    />
  )
}
