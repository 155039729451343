import {Button, LinkWrapper} from '~/design-system/foundations'
import {HgTooltip} from '~/design-system/hg/components'
import {type HgButtonLinkProps} from '../types'
import {renderWithTooltip} from '../utils'
import {hgButtonVariants} from '../variants'
import HgButtonContent, {PrimaryButtonWrapper} from './HgButtonContent'

export const HgButtonLink = ({buttonRef, ...props}: HgButtonLinkProps) => {
  const {
    className,
    variant = 'primary',
    size = 'medium',
    uiState,
    contentType = 'label',
    iconProps,
    children,
    analyticsEventName,
    ...linkProps
  } = props

  const {button} = hgButtonVariants({
    variant,
    size,
    uiState,
    contentType,
  })

  const renderLinkContent = () => {
    if (variant === 'primary') {
      return (
        <PrimaryButtonWrapper>
          <HgButtonContent
            variant="primary"
            uiState={uiState}
            iconProps={iconProps}
            contentType={contentType}
            size={size}
          >
            {children}
          </HgButtonContent>
        </PrimaryButtonWrapper>
      )
    }

    return (
      <HgButtonContent
        variant={variant}
        uiState={uiState}
        iconProps={iconProps}
        size={size}
        contentType={contentType}
      >
        {children}
      </HgButtonContent>
    )
  }

  const renderBaseLink = () => (
    <Button
      asChild
      ref={buttonRef}
      className={button({class: className})}
      data-button-variant={variant}
    >
      <LinkWrapper data-analytics-event-name={analyticsEventName} {...linkProps}>
        {renderLinkContent()}
      </LinkWrapper>
    </Button>
  )

  if (renderWithTooltip(props)) {
    return (
      <HgTooltip content={props.tooltip} size={size} side="bottom">
        {renderBaseLink()}
      </HgTooltip>
    )
  }

  return renderBaseLink()
}

export default HgButtonLink
