import {tv} from 'tailwind-variants'

export const hgPullQuoteVariants = tv({
  slots: {
    quote:
      'text-text-default before:content-[open-quote] after:content-[close-quote]',
    content: 'text-text-default',
  },
  variants: {
    width: {
      default: {quote: 'tiempos-heading-2', content: 'arcadia-body-2'},
      fullBleed: {quote: 'tiempos-heading-1', content: 'arcadia-body-1'},
      fullWidth: {quote: 'tiempos-heading-5', content: 'arcadia-body-2'},
    },
  },
  defaultVariants: {
    width: 'default',
  },
})
