import {tv} from 'tailwind-variants'

export const hgArticleListingTextOnlyVariants = tv({
  slots: {
    outerContainer: 'w-full',
    innerContainer: '',
    topicLink: '',
    title: 'block pt-s1 text-text-default',
    subheading: 'max-w-[688px] pt-s1 text-text-subdued',
    authorContainer: 'w-fit text-text-default',
  },
  variants: {
    size: {
      'display-4': {
        title: 'pt-s2 tiempos-display-4',
        subheading: 'pt-s2 arcadia-body-1',
        authorContainer: 'mt-s4',
      },
      'heading-1': {
        title: 'pt-s2 tiempos-heading-1',
        subheading: 'pt-s2 arcadia-body-1',
        authorContainer: 'mt-s4',
      },
      'heading-2': {
        title: 'tiempos-heading-2',
        subheading: 'arcadia-body-2',
        authorContainer: 'mt-s4',
      },
      'heading-5': {
        title: 'tiempos-heading-5',
        subheading: 'arcadia-body-2',
        authorContainer: 'mt-s4',
      },
      'heading-6': {
        title: 'tiempos-heading-6',
        subheading: 'arcadia-body-3',
        authorContainer: 'mt-s4',
      },
      'heading-7': {
        title: 'tiempos-heading-7',
        subheading: 'arcadia-body-3',
        authorContainer: 'mt-s3',
      },
      'heading-10': {
        title: 'tiempos-heading-10',
        subheading: 'arcadia-body-3',
        authorContainer: 'mt-s3',
      },
    },
    alignment: {
      left: {},
      center: {
        innerContainer: 'place-items-center text-center',
      },
    },
    topicLinkVariant: {
      default: {},
      primary: {topicLink: 'text-text-primary'},
    },
  },
})
