import {Slot} from '@radix-ui/react-slot'
import * as React from 'react'

const Breadcrumb = ({ref, ...props}: React.ComponentPropsWithRef<'nav'>) => (
  <nav ref={ref} aria-label="breadcrumb" {...props} />
)

const BreadcrumbList = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'ol'>) => (
  <ol ref={ref} className={className} {...props} />
)

const BreadcrumbItem = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'li'>) => (
  <li ref={ref} className={className} {...props} />
)

const BreadcrumbLink = ({
  ref,
  asChild,
  className,
  ...props
}: React.ComponentPropsWithRef<'a'> & {asChild?: boolean}) => {
  const Comp = asChild ? Slot : 'a'

  return <Comp ref={ref} className={className} {...props} />
}

const BreadcrumbPage = ({
  ref,
  className,
  ...props
}: React.ComponentPropsWithRef<'span'>) => (
  <span
    ref={ref}
    role="link"
    aria-disabled="true"
    aria-current="page"
    className={className}
    {...props}
  />
)

const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<'li'>) => (
  <li role="presentation" aria-hidden="true" className={className} {...props}>
    {children ?? '/'}
  </li>
)

const BreadcrumbEllipsis = ({className, ...props}: React.ComponentProps<'span'>) => (
  <span role="presentation" aria-hidden="true" className={className} {...props}>
    <BreadcrumbEllipsis className="h-4 w-4" />
    <span className="sr-only">More</span>
  </span>
)

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
}
