import {type ReactNode} from 'react'
import {grid} from '~/design-system/foundations'
import {tv, type VariantProps} from 'tailwind-variants'
import {
  HgButtonLink,
  type HgButtonLinkProps,
  HgTextLink,
  type HgTextLinkProps,
} from '~/design-system/hg/components'

const subsectionTitle = tv({
  base: 'col-span-full grid grid-cols-subgrid',
  variants: {
    layout: {
      fullWidth: '',
      inset: 'lg:col-span-12 lg:col-start-3',
    },
  },
})

export type HgSubsectionTitleLayout = NonNullable<
  VariantProps<typeof subsectionTitle>['layout']
>

export type HgSubsectionTitleProps = {
  title: ReactNode
  subhead?: ReactNode
  layout?: HgSubsectionTitleLayout
  linkProps?:
    | ({type: 'text'} & Omit<HgTextLinkProps, 'variant'>)
    | ({type: 'button'} & Omit<HgButtonLinkProps, 'variant'>)
}

const HgSubsectionTitle = ({
  title,
  subhead,
  linkProps,
  layout = 'inset',
}: HgSubsectionTitleProps) => {
  return (
    <div className="w-full">
      <div className={grid({class: 'mx-auto pt-s9'})}>
        <div className={subsectionTitle({layout})}>
          <div className="col-span-full grid grid-cols-subgrid gap-y-s4">
            <div className="col-span-full md:col-span-8">
              <h3 className="text-text-default arcadia-subheading-5">{title}</h3>
              <p className="text-text-subdued arcadia-body-1">{subhead}</p>
            </div>
            {linkProps && (
              <div className="col-span-full grid md:items-end md:justify-items-end md:[grid-column:9/-1]">
                {linkProps?.type === 'text' && (
                  <HgTextLink {...linkProps} variant="underline" />
                )}
                {linkProps?.type === 'button' && (
                  <HgButtonLink
                    {...(linkProps as HgButtonLinkProps)}
                    variant="tonal"
                    size="medium"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HgSubsectionTitle
