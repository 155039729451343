import {type ReactNode} from 'react'
import {hgPullQuoteVariants} from '~/design-system/hg/components/HgArticleWYSIWYG/_components/HgPullQuote/variants'
import {cn} from '~/design-system/utils'

type HgPullQuoteProps = {
  quote: ReactNode
  author?: ReactNode
  context?: ReactNode
  width?: keyof typeof hgPullQuoteVariants.variants.width
  className?: string
}

export const HgPullQuote = ({
  quote,
  author,
  context,
  width = 'default',
  className,
}: HgPullQuoteProps) => {
  const {quote: quoteVariant, content} = hgPullQuoteVariants({
    width,
  })

  return (
    <div
      aria-hidden="true"
      className={cn('flex flex-col items-center space-y-s3 text-center', className)}
    >
      <p className={quoteVariant()}>{quote}</p>
      {author && (
        <p className={content()}>
          {author}
          {context && ', '}
          {context}
        </p>
      )}
    </div>
  )
}

export const HgArticleWYSIWYGPullQuote = (props: HgPullQuoteProps) => {
  return (
    <HgPullQuote
      {...props}
      className={cn(
        'col-span-full grid lg:col-span-12 lg:col-start-3',
        props.className
      )}
    />
  )
}
