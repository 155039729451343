import {tv} from 'tailwind-variants'

const grid = tv({
  base: 'grid max-w-grid-max-width grid-cols-layout gap-x-grid-column-gap',
  variants: {
    margins: {
      true: 'mx-24 lg:mx-32 3xl:mx-auto',
    },
    padding: {
      true: 'px-s5',
      false: 'px-s0',
    },
  },
  defaultVariants: {
    margins: false,
    padding: true,
  },
})

export const gridItem = tv({
  base: 'col-span-full',
  variants: {
    size: {
      max: 'col-span-full lg:col-span-12 lg:col-start-3',
      heading: 'col-span-full md:col-span-8 md:col-start-3 lg:col-start-5',
    },
  },
})

export default grid
