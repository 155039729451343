import {convertToDateString} from '~/design-system/utils'
import {HgAspectRatio} from '~/design-system/hg/components'
import HgArticleListingTextOnly from '../TextOnly'
import ArticleLinkBox from '../ArticleLinkBox'
import {type HgArticleListingIndexProps} from './types'
import {hgArticleListingIndexVariants} from './variants'

const HgArticleListingIndex = ({
  aspectRatio,
  image,
  datePublished,
  slug,
  ...textProps
}: HgArticleListingIndexProps) => {
  const {wrapper, innerContainer, imageContainer, textContainer, dateContainer} =
    hgArticleListingIndexVariants()
  return (
    <ArticleLinkBox slug={slug} className={wrapper()}>
      <div className={innerContainer()}>
        <HgAspectRatio
          aspectRatio={aspectRatio}
          classNames={{
            assetWrapper: 'rounded-none',
            wrapper: imageContainer(),
          }}
        >
          {image}
        </HgAspectRatio>
        <div className={textContainer()}>
          <HgArticleListingTextOnly size="heading-5" slug={slug} {...textProps} />
        </div>

        <div className={dateContainer()}>
          <p className="text-text-default arcadia-body-4">
            {convertToDateString(datePublished)}
          </p>
        </div>
      </div>
    </ArticleLinkBox>
  )
}

export default HgArticleListingIndex
