import {tv} from 'tailwind-variants'

export const hgButtonVariants = tv({
  slots: {
    button:
      'group inline-flex w-fit items-center justify-center whitespace-nowrap text-text-default outline-2 outline-offset-4 outline-border-focus transition-colors duration-300 ease-out arcadia-ui-1 focus-visible:outline aria-busy:cursor-default aria-disabled:cursor-default',
    content: '',
  },
  variants: {
    variant: {
      primary: {
        button: `relative overflow-hidden rounded-4xl bg-surface-primary text-text-on-primary hover:bg-surface-primary-hover focus-visible:bg-surface-primary-hover active:bg-surface-primary-active aria-busy:bg-surface-primary-active aria-disabled:bg-surface-primary-disabled`,
        content: 'text-icon-on-primary',
      },
      tonal: {
        button: `group rounded-5xl bg-surface-emphasized hover:bg-surface-emphasized-hover focus-visible:bg-surface-emphasized-hover active:bg-surface-emphasized-active aria-busy:bg-surface-emphasized-active aria-disabled:bg-surface-emphasized aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
      frosted: {
        button: `group rounded-5xl border border-solid border-border-frosted bg-surface-frosted backdrop-blur-[20px] hover:bg-surface-frosted-hover focus-visible:bg-surface-frosted-hover active:bg-surface-frosted-active aria-busy:bg-surface-frosted-active aria-disabled:bg-surface-frosted aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
      elevated: {
        button: `group rounded-5xl bg-surface-elevated shadow-[0px_0px_3px_0px_rgba(28,28,35,0.09),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_10px_16px_0px_rgba(28,28,35,0.02)] hover:bg-surface-elevated-hover focus-visible:bg-surface-elevated-hover active:bg-surface-elevated-active aria-busy:bg-surface-elevated-active aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
      text: {
        button: `group rounded-5xl hover:bg-surface-frosted-hover focus-visible:bg-surface-frosted-hover active:bg-surface-frosted-active aria-busy:bg-surface-frosted-active aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
    },
    size: {
      xl: {button: ''},
      medium: {button: 'h-40 px-20 arcadia-ui-1'},
      small: {button: 'h-32 px-16 arcadia-ui-2'},
      xs: {button: 'h-24 px-12 arcadia-ui-3'},
    },
    contentType: {
      label: {},
      icon: {button: 'rounded-full p-0'},
    },
    uiState: {
      focused: {
        button: 'outline',
      },
      active: {button: ''},
      disabled: {button: ''},
      hovered: {button: ''},
      pending: {button: ''},
    },
    position: {
      left: {content: 'mr-8'},
      right: {content: 'ml-8'},
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      uiState: 'focused',
      class: {button: 'bg-surface-primary-hover'},
    },
    {
      variant: 'primary',
      uiState: 'active',
      class: {
        button: 'focus-visible:surface-primary-active bg-surface-primary-active',
      },
    },
    {
      variant: 'tonal',
      uiState: 'active',
      class: {button: 'bg-surface-emphasized-active'},
    },
    {
      variant: 'frosted',
      uiState: 'active',
      class: {button: 'bg-surface-frosted-active'},
    },
    {
      variant: 'elevated',
      uiState: 'active',
      class: {button: 'bg-surface-elevated-active'},
    },
    {
      variant: 'text',
      uiState: 'active',
      class: {button: 'bg-surface-frosted-active'},
    },
    {
      variant: 'tonal',
      uiState: 'focused',
      class: {button: 'bg-surface-emphasized-hover'},
    },
    {
      variant: 'frosted',
      uiState: 'focused',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      variant: 'elevated',
      uiState: 'focused',
      class: {button: 'bg-surface-elevated-hover'},
    },
    {
      variant: 'text',
      uiState: 'focused',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      variant: 'primary',
      uiState: 'hovered',
      class: {button: 'bg-surface-primary-hover'},
    },
    {
      variant: 'tonal',
      uiState: 'hovered',
      class: {button: 'bg-surface-emphasized-hover'},
    },
    {
      variant: 'frosted',
      uiState: 'hovered',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      variant: 'elevated',
      uiState: 'hovered',
      class: {button: 'bg-surface-elevated-hover'},
    },
    {
      variant: 'text',
      uiState: 'hovered',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      contentType: 'icon',
      size: 'xl',
      class: {button: 'max-h-48 min-h-48 min-w-48 max-w-48'},
    },
    {
      contentType: 'icon',
      size: 'medium',
      class: {button: 'max-h-40 min-h-40 min-w-40 max-w-40'},
    },
    {
      contentType: 'icon',
      size: 'small',
      class: {button: 'max-h-32 min-h-32 min-w-32 max-w-32'},
    },
    {
      contentType: 'icon',
      size: 'xs',
      class: {button: 'max-h-24 min-h-24 min-w-24 max-w-24'},
    },
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'medium',
  },
})
