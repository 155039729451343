import * as React from 'react'

export type InputProps = React.ComponentPropsWithRef<'input'>

const Input = ({ref, type, ...props}: InputProps) => {
  return <input type={type} ref={ref} {...props} />
}

export default Input

export const EmailInput = ({ref, ...props}: InputProps) => (
  <Input type="email" ref={ref} {...props} />
)
